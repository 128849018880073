<template>
  <div class="screen-event-list-own has-tab-bar">
    <app-bar
      title="Mijn events"
      :show-back-button="false"
      :show-profile-button="true"
    />
    <div class="container">
      <div class="row wrapper-tabs margin-bottom-15">
        <div class="col-6">
          <a
            href="#"
            class="btn btn-block"
            :class="[
              currentTab === 'signedUp' ? 'btn-green' : 'btn-white-border-green'
            ]"
            @click.prevent="setTab('signedUp')"
          >
            aangemeld
          </a>
        </div>
        <div class="col-6">
          <a
            href="#"
            class="btn btn-block"
            :class="[
              currentTab === 'created' ? 'btn-green' : 'btn-white-border-green'
            ]"
            @click.prevent="setTab('created')"
          >
            aangemaakt
          </a>
        </div>
      </div>

      <div v-if="currentTab === 'signedUp'">
        <h1 class="page-title text-center">Deze week</h1>
        <template v-if="eventSignedUpListWithinAWeek.length > 0">
          <event-item
            v-for="event in eventSignedUpListWithinAWeek"
            :key="event.dateId"
            :name="event.name"
            :activity-icon="event.activityIcon"
            :event-image="event.imageUrl"
            :cost="event.costFeeIncluded"
            :start-time="event.startTime"
            :end-time="event.endTime"
            :date="event.date"
            :location="event.locationName"
            :maximum-participants="event.maximumParticipants"
            :number-of-participants="event.numberOfParticipants"
            :cursor-pointer="true"
            :origin="origin"
            :button-text="null"
            :date-id="event.dateId"
            @click.native="routeToEventView(event.dateId, event.securityHash)"
          />
        </template>
        <p v-else class="text-center">
          Deze week zijn er geen evenementen gevonden
        </p>
        <h1 class="page-title text-center">Later</h1>
        <template v-if="eventSignedUpListAfterAWeek.length > 0">
          <event-item
            v-for="event in eventSignedUpListAfterAWeek"
            :key="event.dateId"
            :name="event.name"
            :activity-icon="event.activityIcon"
            :event-image="event.imageUrl"
            :cost="event.costFeeIncluded"
            :start-time="event.startTime"
            :end-time="event.endTime"
            :date="event.date"
            :location="event.locationName"
            :maximum-participants="event.maximumParticipants"
            :number-of-participants="event.numberOfParticipants"
            :cursor-pointer="true"
            :origin="origin"
            :button-text="null"
            @click.native="routeToEventView(event.dateId, event.securityHash)"
          />
        </template>
        <p v-else class="text-center">
          Na deze week zijn er geen evenementen gevonden
        </p>
      </div>
      <div v-if="currentTab === 'created'">
        <template
          v-if="eventOwnedList !== undefined && eventOwnedList.length > 0"
        >
          <h1 class="page-title text-center">Binnenkort</h1>
          <template
            v-if="eventOwnedFuture !== undefined && eventOwnedFuture.length > 0"
          >
            <event-item
              v-for="event in eventOwnedFuture"
              :key="event.dateId"
              :name="event.name"
              :activity-icon="event.activityIcon"
              :event-image="event.imageUrl"
              :cost="event.costFeeIncluded"
              :start-time="event.startTime"
              :end-time="event.endTime"
              :date="event.date"
              :location="event.locationName"
              :maximum-participants="event.maximumParticipants"
              :number-of-participants="event.numberOfParticipants"
              :cursor-pointer="true"
              :origin="origin"
              :button-text="null"
              @click.native="routeToEventView(event.dateId, event.securityHash)"
            />
          </template>
          <p v-else class="text-center">Je hebt geen toekomstige evenementen</p>

          <h1 class="page-title text-center">Afgelopen</h1>
          <event-item
            v-for="event in createdEventsinPast"
            :key="event.dateId"
            :name="event.name"
            :activity-icon="event.activityIcon"
            :event-image="getEventImage(event)"
            :cost="event.costFeeIncluded"
            :start-time="event.startTime"
            :end-time="event.endTime"
            :date="event.date"
            :location="event.locationName"
            :maximum-participants="event.maximumParticipants"
            :number-of-participants="event.participantCount"
            :cursor-pointer="true"
            :origin="origin"
            :button-text="null"
            :is-ended="event.isEnded"
            @click.native="routeToEventView(event.dateId, event.securityHash)"
          />
          <infinite-loading
            :identifier="infiniteId"
            @infinite="infiniteHandler"
          >
            <span slot="no-more"></span>
            <span slot="no-results">
              <p class="text-sm mb-8">
                Er zijn geen events gevonden. Kies een andere sport of een
                andere locatie.
              </p>
            </span>
          </infinite-loading>
        </template>
        <p v-else class="text-center">
          Je hebt nog geen evenementen aangemaakt
        </p>
      </div>
    </div>
    <tab-bar selected="events" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import EventItem from '@/components/EventItem'
import { EVENT_SIGNED_UP_LIST, EVENT_OWNED_LIST } from '@/store/actions/event'
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
import { EVENT_CREATED_BY_PARTICIPANT } from '../../store/actions/event'
import InfiniteLoading from 'vue-infinite-loading'
import noImage from '@/assets/images/img_no-image.png'

export default {
  name: 'EventListOwn',
  components: {
    AppBar,
    TabBar,
    EventItem,
    InfiniteLoading
  },
  data() {
    return {
      currentTab: 'signedUp', // created / signedUp
      eventSignedUpListWithinAWeek: [],
      eventSignedUpListAfterAWeek: [],
      origin: '',
      skip: 0,
      infiniteId: +new Date()
    }
  },
  computed: {
    ...mapGetters(['createdEventsinPast']),
    ...mapState({
      eventOwnedList: (state) => state.event.eventOwnedList,
      eventOwnedPast: (state) => state.event.eventOwnedPast,
      eventOwnedFuture: (state) => state.event.eventOwnedFuture,
      eventSignedUpList: (state) => state.event.eventSignedUpList
    })
  },
  watch: {
    eventSignedUpList(eventSignedUpList) {
      // Check current user is joined
      if (eventSignedUpList !== null) {
        this.eventSignedUpListWithinAWeek = _.filter(eventSignedUpList, {
          dateWithinAWeek: true
        })
        this.eventSignedUpListAfterAWeek = _.filter(eventSignedUpList, {
          dateWithinAWeek: false
        })
      }
    }
  },
  created: function () {
    this.$store.dispatch(EVENT_SIGNED_UP_LIST)
    this.$store.dispatch(EVENT_OWNED_LIST)
    this.origin = this.$route.path
  },
  methods: {
    setTab: function (tabName) {
      this.currentTab = tabName
    },
    routeToEventView: function (dateId, securityHash) {
      this.$router.push(
        `/events/${dateId}/${securityHash}?origin=event-list-own`
      )
    },
    getEventImage: function (event) {
      return event.image
        ? event.image
        : event.activity && event.activity.defaultImage
        ? event.activity.image
        : noImage
    },
    async infiniteHandler($state) {
      const response = await this.$store.dispatch(
        EVENT_CREATED_BY_PARTICIPANT,
        { skip: 0, limit: 10 }
      )
      if (response && response.length) {
        $state.loaded()
      } else {
        $state.complete()
      }
    }
  }
}
</script>

<style scoped>
.wrapper-tabs {
  margin-top: 15px;
}

@media (max-width: 380px) {
  .wrapper-tabs a {
    font-size: 1rem;
  }
}

@media (max-width: 325px) {
  .wrapper-tabs a {
    font-size: 0.75rem;
    padding-left: 3px;
    padding-right: 3px;
  }
}

.wrapper-tabs div:first-child {
  padding-right: 7.5px;
}

.wrapper-tabs div:last-child {
  padding-left: 7.5px;
}

.page-title {
  margin: 30px 0;
}
</style>
