<template>
  <div>
    <template v-if="eventEdit && event">
      <app-bar
        :show-back-button="true"
        :show-profile-button="false"
        :show-context-menu="contextMenuItems && contextMenuItems.length > 0"
        @toggle-context-menu="showContextMenu = !showContextMenu"
      />
      <div
        class="container container-event-view"
        :class="{ 'has-fixed-action-button': true }"
      >
        <h1>Activiteit aanpassen</h1>

        <!-- Title -->
        <edit-field
          id="name"
          :button-disabled="eventEdit.name === event.name"
          :disabled="isEditting && isEditting !== 'name'"
          :is-loading="isLoading"
          :title="$t('title')"
          :value="event.name"
          @edit="setEditField"
          @cancel="event.name = eventEdit.name"
          @save="saveSingle"
        >
          <input-field
            id="title"
            v-model="event.name"
            class="mt-2 mb-2"
            :default-value="$t('title')"
          />
        </edit-field>

        <!-- Description -->
        <edit-field
          id="description"
          :button-disabled="event.description === eventEdit.description"
          :disabled="isEditting && isEditting !== 'description'"
          :is-loading="isLoading"
          :title="$t('description')"
          :value="event.description"
          @edit="setEditField"
          @cancel="event.description = eventEdit.description"
          @save="saveSingle"
        >
          <text-field
            id="description"
            v-model="event.description"
            class="my-2"
            :default-value="$t('description-default')"
          />
        </edit-field>

        <!-- Activity type -->
        <edit-field
          v-if="selectedSport"
          id="activityID"
          :button-disabled="selectedSport === orginalSelectedSport"
          :disabled="isEditting && isEditting !== 'activityID'"
          :is-loading="isLoading"
          :title="$t('sport')"
          :value="selectedSport"
          @edit="setEditField"
          @cancel="event.activityID = eventEdit.activityID"
          @save="saveSingle"
        >
          <dropdown
            class="mb-4"
            :disabled="false"
            :items="activityKeyValueList"
            :selected="event.activityID"
            empty-option="Kies activiteit"
            theme="rounded"
            @on-select="
              (val) => {
                event.activityID = val
              }
            "
          />
        </edit-field>

        <!-- Date -->
        <event-edit-date
          :event="event"
          :is-loading="isLoading"
          :is-editting="isEditting"
          :single-event="singleEvent"
          :single-event-date="singleEventDate"
          :updated-single-event-date="updatedSingleEventDate"
          @on-save="onSaveDate"
          @on-change-date-time="changeDateTime"
          @on-edit="setEditField"
        />

        <!-- Location -->
        <event-edit-location
          v-if="event.location"
          :is-loading="isLoading"
          :is-editting="isEditting"
          :location="event.location"
          :previous-locations="previousLocations"
          @reload-locations="reloadPreviousLocations"
          @on-edit="setEditField"
          @on-save="saveNewLocation"
        />

        <!-- Max participants -->
        <edit-field
          id="maximumParticipants"
          :title="$t('maxParticipants')"
          :value="event.maximumParticipants"
          :disabled="isEditting && isEditting !== 'maximumParticipants'"
          :button-disabled="
            eventEdit.maximumParticipants === event.maximumParticipants
          "
          :is-loading="isLoading"
          @edit="setEditField"
          @save="saveSingle"
        >
          <input-field
            id="maximumParticipants"
            v-model="event.maximumParticipants"
            class="my-2"
            :default-value="0"
            type="number"
            pattern="[0-9]*"
            inputmode="numeric"
          />
        </edit-field>

        <!-- Private event -->
        <edit-field
          id="private"
          title="Zichtbaarheid"
          :value="currentVisibility"
          :disabled="isEditting && isEditting !== 'private'"
          :is-loading="isLoading"
          @edit="setEditField"
          @save="saveSingle"
        >
          <template>
            <radio-button
              :selected="selectedVisibility"
              name="changeVisibility"
              value="all"
              :label="VISIBILITY_ALL"
              @change="(val) => (selectedVisibility = val)"
            />
            <radio-button
              v-if="event.groupID"
              :selected="selectedVisibility"
              name="changeVisibility"
              value="group"
              :label="VISIBILITY_GROUP"
              @change="(val) => (selectedVisibility = val)"
            />
            <radio-button
              v-if="!event.groupID"
              :selected="selectedVisibility"
              name="changeVisibility"
              value="private"
              :label="VISIBILITY_PRIVATE"
              @change="(val) => (selectedVisibility = val)"
            />
          </template>
        </edit-field>

        <!-- image -->
        <event-edit-image
          :image="event.image"
          :image-url="event.imageUrl"
          :is-editting="isEditting"
          :is-loading="isLoading"
          :is-updated="imageUpdated"
          @edit="setEditField"
          @cancel="resetImage"
          @change-file="onFileChange"
          @delete="deleteImage"
          @save="saveImage"
        />
      </div>
    </template>
    <spinner-loader v-else />
    <context-menu
      v-if="contextMenuItems"
      :show="showContextMenu"
      :items="contextMenuItems"
      @close="showContextMenu = false"
      @click="(clickAction) => clickAction()"
    />
    <modal
      :show="showCancelModal"
      show-close-icon
      @close="showCancelModal = false"
    >
      <template v-slot:header>Annuleer event</template>
      <slot>
        <div class="text-sm mb-4">
          Weet je zeker dat je dit event wilt annuleren?
        </div>
      </slot>
      <template v-slot:footer>
        <div class="flex w-full">
          <button
            class="button button-secondary flex-1 w-full mr-2"
            @click="showCancelModal = false"
          >
            Nee, ga terug
          </button>
          <button
            class="button button-danger flex-1 w-full ml-2"
            @click="cancelEvent"
          >
            Ja, annuleer
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { DateTime } from 'luxon'
import { getOrientation } from '@/utils/getOrientation'
import { ACTIVITY_LIST } from '@/store/actions/activity'
import { DATE_LOADING, DATE_UPDATE_DATE } from '@/store/actions/date'
import {
  EVENT_DELETE,
  EVENT_EDIT,
  EVENT_UPDATE_IMAGE,
  EVENT_UPDATE_SINGLE
} from '@/store/actions/event'
import { LOCATION_BY_USER_ID, LOCATION_CREATE } from '@/store/actions/location'
import AppBar from '@/components/AppBar'
import ContextMenu from '@/components/ContextMenu'
import Dropdown from '@/components/Dropdown'
import EditField from '@/components/EditField'
import EventEditDate from '@/views/event-edit/EventEditDate.vue'
import EventEditImage from '@/views/event-edit/EventEditImage.vue'
import EventEditLocation from '@/views/event-edit/EventEditLocation.vue'
import IconTrash from '@/components/icons/IconTrash'
import InputField from '@/components/InputField'
import Modal from '@/components/Modal'
import SpinnerLoader from '@/components/SpinnerLoader'
import TextField from '@/components/TextField'
import RadioButton from '@/components/RadioButton'
import noImage from '@/assets/images/img_no-image.png'

export default {
  name: 'EventEdit',
  components: {
    AppBar,
    ContextMenu,
    Dropdown,
    EditField,
    EventEditDate,
    EventEditImage,
    EventEditLocation,
    InputField,
    Modal,
    RadioButton,
    SpinnerLoader,
    TextField
  },
  data: function () {
    return {
      dateTimeToUpdate: {},
      event: {},
      imageOrientation: undefined,
      imageUpdated: false,
      isEditting: null,
      showCancelModal: false,
      showContextMenu: false,
      singleEventDate: {},
      updatedSingleEventDate: {},
      selectedVisibility: undefined,
      VISIBILITY_ALL: 'Deze activiteit is voor iedereen zichtbaar op Inviplay',
      VISIBILITY_GROUP:
        'Deze activiteit is alleen zichtbaar voor de leden van jouw groep',
      VISIBILITY_PRIVATE: 'Deze activiteit is niet zichtbaar op Inviplay'
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) =>
        state.event.status === 'loading' ||
        state.date.status === 'loading' ||
        state.date.loading,
      activityKeyValueList: (state) => state.activity.activityKeyValueList,
      eventEdit: (state) => state.event.eventEdit,
      previousLocations: (state) => state.location.previousLocations
    }),
    selectedSport: function () {
      let sport
      if (this.activityKeyValueList.length > 0) {
        sport = this.activityKeyValueList.find(
          (a) => a.key === parseInt(this.event.activityID)
        )
      }
      return sport ? sport.value : 'Kies sport'
    },
    orginalSelectedSport: function () {
      let sport
      if (this.activityKeyValueList.length > 0) {
        sport = this.activityKeyValueList.find(
          (a) => a.key === parseInt(this.eventEdit.activityID)
        )
      }
      return sport ? sport.value : 'Kies sport'
    },
    singleEvent() {
      return !this.eventEdit.recurring || this.eventEdit.recurring === 'none'
    },
    contextMenuItems: function () {
      return [
        {
          key: 'cancel',
          text: 'Annuleer event',
          clickAction: () => (this.showCancelModal = true),
          icon: IconTrash
        }
      ]
    },
    currentVisibility() {
      return this.event.private && !this.event.groupID
        ? this.VISIBILITY_PRIVATE
        : this.event.private && this.event.groupID
        ? this.VISIBILITY_GROUP
        : this.VISIBILITY_ALL
    }
  },
  watch: {
    eventEdit(eventEdit) {
      this.event = { ...eventEdit }
      if (this.singleEvent) {
        this.selectedDateId = eventEdit.dates[0].id
      }
      this.singleEventDate = {
        id: eventEdit.dates[0].id,
        startDateTime: eventEdit.dates[0].startDateTime,
        endDateTime: eventEdit.dates[0].endDateTime
      }
      this.selectedVisibility =
        eventEdit.private && !eventEdit.groupID
          ? 'private'
          : eventEdit.private && eventEdit.groupID
          ? 'group'
          : 'all'
    },
    singleEventDate(date) {
      this.updatedSingleEventDate = { ...date }
    }
  },
  created() {
    this.$store.dispatch(ACTIVITY_LIST)
    this.$store.dispatch(LOCATION_BY_USER_ID)
    this.$store.dispatch(EVENT_EDIT, {
      id: this.$route.params.id,
      securityHash: this.$route.params.securityHash
    })
  },
  methods: {
    setEditField: function (id) {
      this.isEditting = id
    },
    onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      this.event.image = files[0]
      this.imageUpdated = true
      const vm = this
      getOrientation(files[0], function (orientation) {
        switch (orientation) {
          case 3:
            vm.imageOrientation = 'orientation-3'
            break
          case 6:
            vm.imageOrientation = 'orientation-6'
            break
          case 8:
            vm.imageOrientation = 'orientation-8'
            break
        }
      })
      // Set image url in view
      const reader = new FileReader()
      reader.onload = (e) => {
        this.event.imageUrl = e.target.result
      }
      reader.readAsDataURL(files[0])
    },
    cancelEvent() {
      this.$store
        .dispatch(EVENT_DELETE, this.event.id)
        .then(() => {
          this.$router.push('/events')
        })
        .catch((e) => {
          alert('Er is iets fout gegaan: ' + e.message)
        })
    },
    saveSingle: async function (field) {
      const response = await this.$store.dispatch(EVENT_UPDATE_SINGLE, {
        eventId: this.event.id,
        updateObject: {
          [field]:
            field === 'private'
              ? this.selectedVisibility === 'private' ||
                this.selectedVisibility === 'group'
              : this.event[field]
        }
      })
      if (response) {
        this.$store.dispatch(EVENT_EDIT, {
          id: this.$route.params.id,
          securityHash: this.$route.params.securityHash
        })
      } else {
        alert('Er ging iets fout, wijziging niet opgeslagen')
        this.event = { ...this.eventEdit }
      }
      this.isEditting = null
      this.$store.commit(DATE_LOADING, false)
    },
    onSaveDate: async function ({ singleEvent, selectedDateItem }) {
      if (!singleEvent) {
        if (!this.dateIsValid(this.updatedSingleEventDate)) {
          alert('Eindtijd kan niet voor begintijd liggen')
        } else {
          this.$store.commit(DATE_LOADING, true)
          const updates = await Promise.all(
            this.event.dates.map(async (date) => {
              this.changeDateTime({
                dateTime: date.startDateTime,
                startDateTime: this.updatedSingleEventDate.startDateTime,
                endDateTime: this.updatedSingleEventDate.endDateTime
              })
              const response = await this.$store.dispatch(DATE_UPDATE_DATE, {
                dateId: date.id,
                dateItem: this.dateTimeToUpdate
              })
              return response
            })
          )
          if (updates.length > 0) {
            this.$store.dispatch(EVENT_EDIT, {
              id: this.$route.params.id,
              securityHash: this.$route.params.securityHash
            })
          } else {
            alert('Er ging iets fout, wijziging niet opgeslagen')
          }
          this.resetEditDates()
        }
      } else {
        if (
          !this.singleEvent &&
          !this.dateTimeToUpdate.endDateTime &&
          !this.dateTimeToUpdate.startDateTime
        ) {
          this.changeDateTime({
            dateTime: selectedDateItem.startDateTime,
            ...selectedDateItem
          })
        }
        if (!this.dateIsValid(this.dateTimeToUpdate)) {
          alert('Eindtijd kan niet voor begintijd liggen')
        } else {
          const updateDate = await this.$store.dispatch(DATE_UPDATE_DATE, {
            dateId: selectedDateItem.id,
            dateItem: this.dateTimeToUpdate
          })
          if (updateDate) {
            this.$store.dispatch(EVENT_EDIT, {
              id: this.$route.params.id,
              securityHash: this.$route.params.securityHash
            })
          } else {
            alert('Er ging iets fout, wijziging niet opgeslagen')
          }
          this.resetEditDates()
        }
      }
    },
    changeDateTime(dateTimeObj) {
      const date = DateTime.fromISO(dateTimeObj.dateTime)
      const start = DateTime.fromISO(dateTimeObj.startDateTime)
      const end = DateTime.fromISO(dateTimeObj.endDateTime)
      this.dateTimeToUpdate = {
        startDateTime: start
          .set({
            year: date.year,
            month: date.month,
            day: date.day
          })
          .toISO(),
        endDateTime: end
          .set({
            year: date.year,
            month: date.month,
            day: date.day
          })
          .toISO()
      }
    },
    dateIsValid(dateToCheck) {
      return dateToCheck.startDateTime < dateToCheck.endDateTime
    },
    resetEditDates() {
      this.$store.commit(DATE_LOADING, false)
      this.isEditting = null
      this.singleEventDate = {}
      this.updatedSingleEventDate = {}
      this.dateTimeToUpdate = {}
    },
    saveNewLocation: async function (location, isNew) {
      this.$store.commit(DATE_LOADING, true)
      if (isNew) {
        this.$store.dispatch(LOCATION_CREATE, location).then(({ data }) => {
          this.event.locationID = data.id
          this.saveSingle('locationID')
          this.$store.dispatch(LOCATION_BY_USER_ID)
        })
      } else {
        this.event.locationID = location.id
        this.saveSingle('locationID')
      }
    },
    deleteImage() {
      this.imageUpdated = true
      this.event.image = ''
      this.event.imageUrl = noImage
    },
    resetImage() {
      this.imageUpdated = false
      this.event.image = this.eventEdit.image
      this.event.imageUrl = this.eventEdit.imageUrl
    },
    saveImage: async function () {
      this.$store.commit(DATE_LOADING, true)
      const response = await this.$store.dispatch(EVENT_UPDATE_IMAGE, {
        eventId: this.eventEdit.id,
        image: this.event.image
      })
      if (response) {
        this.$store.dispatch(EVENT_EDIT, {
          id: this.$route.params.id,
          securityHash: this.$route.params.securityHash
        })
      } else {
        alert('Er ging iets fout, wijziging niet opgeslagen')
        this.event = { ...this.eventEdit }
      }
      this.$store.commit(DATE_LOADING, false)
      this.isEditting = null
      this.imageUpdated = false
    },
    reloadPreviousLocations() {
      this.$store.dispatch(LOCATION_BY_USER_ID)
    }
  }
}
</script>
