<template>
  <div>
    <app-bar title="" :show-back-button="true" :show-profile-button="false" />
    <spinner-loader v-if="loading" />
    <div class="container">
      <template v-if="loading">
        <div>Laden...</div>
      </template>
      <template v-else-if="error">
        <div>
          <h1>Oeps</h1>
          <div>Er gaat iets fout...</div>
        </div>
      </template>
      <template v-else>
        <div>
          <h1>Hoe wil je betalen?</h1>
          <p v-if="paymentType === PURCHASE_CREDITCARD" class="text-sm">
            Je koopt een strippenkaart voor deze activiteit waarmee je je
            vervolgens direct kunt aanmelden
          </p>
          <p v-else class="text-sm">
            Je betaalt alleen voor de losse activiteit(en)
          </p>
          <payment-options
            :paypal-fee="paymentType === PURCHASE_CREDITCARD ? 0 : paypalFee"
            :banks="banks"
            @start-payment="startPayment"
          />
          <spinner-loader v-if="paymentLoading" />
        </div>
        <error-modal :show="showErrorModal" @close="showErrorModal = false" />
      </template>
    </div>
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapGetters, mapState } from 'vuex'
import {
  EVENT_GET_CREDITCARDS,
  EVENT_UPDATE_ATTENDANCE
} from '@/store/actions/event'
import PaymentOptions from '@/components/PaymentOptions.vue'
import { OPTION_GET } from '@/store/actions/option'
import { PAYMENT_GET_METHODS } from '@/store/actions/payment'
import { CREDITCARD_PURCHASE } from '@/store/actions/creditcard'
import { PURCHASE_CREDITCARD, SINGLE_PAYMENT } from '@/utils/constants'
import ErrorModal from '@/components/ErrorModal'

export default {
  name: 'EventPayment',
  components: { AppBar, SpinnerLoader, PaymentOptions, ErrorModal },
  data() {
    return {
      dateId: this.$route.params.dateId,
      securityHash: this.$route.params.securityHash,
      error: false,
      paymentMethod: undefined,
      PURCHASE_CREDITCARD: PURCHASE_CREDITCARD,
      SINGLE_PAYMENT: SINGLE_PAYMENT,
      paymentLoading: false,
      showErrorModal: false
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      loading: (state) => state.event.loading || state.date.loading,
      eventDate: (state) => state.date.eventDate,
      datesToUpdate: (state) => state.event.datesToUpdate,
      creditcards: (state) => state.event.creditcards,
      paypalFee: (state) => state.option.options.payments_paypal_fee,
      paymentMethods: (state) => state.payment.paymentMethods.data,
      participantCreditcards: (state) => state.participant.creditcards,
      creditcardAvailable: (state) => state.event.creditcards,
      paymentType: (state) => state.payment.paymentType
    }),
    activeCard: function () {
      return this.creditcards[this.eventDate.eventId]
        ? this.creditcards[this.eventDate.eventId][0]
        : null
    },
    banks: function () {
      return this.paymentMethods?.length
        ? this.paymentMethods.map((issuer) => ({
            key: issuer.bic,
            value: issuer.name
          }))
        : [] || []
    }
  },
  watch: {},
  created: function () {
    this.$store.dispatch(PAYMENT_GET_METHODS)
    this.$store.dispatch(OPTION_GET, 'payments_paypal_fee')
    if (!Object.keys(this.eventDate).length || !this.paymentType) {
      this.error = true
    } else {
      if (this.paymentType === PURCHASE_CREDITCARD) {
        this.$store.dispatch(EVENT_GET_CREDITCARDS, this.eventDate.eventId)
      }
    }
  },
  methods: {
    startPayment: async function ({ selectedPaymentMethod, issuer }) {
      this.paymentLoading = true
      if (this.paymentType === PURCHASE_CREDITCARD) {
        const redirectUrl = await this.$store.dispatch(CREDITCARD_PURCHASE, {
          cardId: this.activeCard.id,
          participantId: parseInt(this.authId),
          paymentMethod: selectedPaymentMethod,
          issuer,
          // FIXME: don't think I need this
          // signUpForEvents: this.datesToUpdate
          //   .filter((date) => date.attendance === 'yes')
          //   .map((date) => date.dateId),
          returnUrl: `/events/${this.dateId}/signup/${this.securityHash}`
        })
        if (redirectUrl) {
          window.location.href = redirectUrl
        } else {
          this.showErrorModal = true
        }
      } else if (this.paymentType === SINGLE_PAYMENT) {
        const response = await this.$store.dispatch(EVENT_UPDATE_ATTENDANCE, {
          eventId: this.eventDate.eventId,
          dateId: this.dateId,
          paymentMethod: selectedPaymentMethod,
          issuer,
          securityHash: this.securityHash,
          dates: this.datesToUpdate
        })
        if (response && response.redirectUrl) {
          window.location.href = response.redirectUrl
        } else {
          this.showErrorModal = true
        }
      } else {
        this.showErrorModal = true
      }
      this.paymentLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
