<template>
  <div>
    <h2 v-if="!selectedPaymentMethod">Kies een betaalmethode</h2>
    <h2 v-else>Kies je bank</h2>
    <template v-if="!selectedPaymentMethod">
      <button
        class="button button-secondary button-icon w-full my-2"
        @click="() => (selectedPaymentMethod = IDEAL)"
      >
        <img
          class="issuer-image"
          src="https://www.mollie.com/external/icons/payment-methods/ideal.svg"
        />
        <div class="text-center flex-1">iDeal</div>
      </button>
      <button
        class="button button-secondary button-icon w-full"
        @click="$emit('start-payment', { selectedPaymentMethod: PAYPAL })"
      >
        <img
          class="issuer-image"
          src="https://www.mollie.com/external/icons/payment-methods/paypal.svg"
        />
        <div class="text-center flex-1">
          PayPal
          {{ paypalFee !== 0 ? `(+${$n(paypalFee, 'currency')})` : '' }}
        </div>
      </button>
    </template>
    <div v-else-if="selectedPaymentMethod === IDEAL" class="pb-8">
      <button
        v-if="selectedPaymentMethod === IDEAL"
        class="button button-secondary button-icon w-full mb-2"
        @click="() => (selectedPaymentMethod = undefined)"
      >
        <icon-chevron-left />
        <div class="flex-1 text-center">Kies andere betaalmethode</div>
      </button>
      <button
        v-for="bank in banks"
        :key="bank.key"
        class="button button-secondary button-icon w-full my-2"
        @click="
          $emit('start-payment', { selectedPaymentMethod, issuer: bank.key })
        "
      >
        <img class="issuer-image" :src="bank.image" />
        <span class="ml-2">{{ bank.value }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { IDEAL, PAYPAL, CREDITCARD } from '../utils/constants'
import IconChevronLeft from '@/components/icons/IconChevronLeft.vue'

export default {
  name: 'PaymentOptions',
  components: { IconChevronLeft },
  props: {
    paypalFee: {
      type: [String, Number],
      default: '0.00'
    },
    banks: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      IDEAL: IDEAL,
      PAYPAL: PAYPAL,
      CREDITCARD: CREDITCARD,
      selectedPaymentMethod: undefined
    }
  },
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
