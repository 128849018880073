<template>
  <div>
    <div class="flex items-center mb-2">
      <h2 class="flex-1 m-0">
        {{ title }}
      </h2>
      <div>
        <a
          href="#"
          class="font-semibold text-sm"
          :class="
            disabled ? 'text-gray-300 no-underline' : 'text-green-600 underline'
          "
          @click.prevent="onClick"
        >
          {{ editField ? 'Annuleren' : 'Aanpassen' }}
        </a>
      </div>
    </div>
    <template v-if="!editField">
      <div v-if="isImage">
        <div
          class="w-32 h-32 rounded-xl bg-cover bg-center"
          :style="{ backgroundImage: `url('${value}')` }"
        />
      </div>
      <div v-else class="text-sm">
        <Nl2br v-if="value" tag="span" :text="value.toString()" />
      </div>
    </template>

    <template v-else>
      <slot />
      <div v-if="!hideSaveButton" class="flex justify-end">
        <button
          class="button button-sm button-primary button-icon"
          :disabled="buttonDisabled"
          @click="onSave"
        >
          <icon-loader v-if="isLoading" />
          <span class="flex-1">Opslaan</span>
        </button>
      </div>
    </template>
    <hr class="border-gray-200 my-4" />
  </div>
</template>

<script>
import Nl2br from 'vue-nl2br'
import IconLoader from '@/components/icons/IconLoader'

export default {
  name: 'EditField',
  components: {
    IconLoader,
    Nl2br
  },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    hideSaveButton: {
      type: Boolean,
      default: false
    },
    isImage: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editField: false
    }
  },
  watch: {
    isLoading: function (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.editField = false
      }
    }
  },
  methods: {
    onClick: function () {
      if (!this.disabled) {
        if (this.editField) {
          this.$emit('cancel', this.id)
        }
        this.editField = !this.editField
        this.$emit('edit', this.editField ? this.id : null)
      }
    },
    onSave: function () {
      this.$emit('save', this.id)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
