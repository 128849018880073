<template>
  <edit-field
    id="selectDate"
    title="Datum en tijd"
    :button-disabled="changeDateButtonIsDisabled"
    :disabled="isEditting && isEditting !== 'selectDate'"
    :is-loading="isLoading"
    :value="currentDate"
    @edit="(id) => $emit('on-edit', id)"
    @cancel="
      () => {
        selectedChangeDateOption = undefined
        eventDateFromRange = {}
      }
    "
    @save="onSave"
  >
    <template v-if="singleEvent">
      <p class="text-sm">Pas hieronder de datum en/of tijden aan.</p>
      <date-time-picker
        title="Datum"
        :date-time="singleEventDate.startDateTime"
        :start-date-time="singleEventDate.startDateTime"
        :end-date-time="singleEventDate.endDateTime"
        class="mb-4"
        @change-date-time="
          (datetimeObj) => $emit('on-change-date-time', datetimeObj)
        "
      />
    </template>
    <template v-else>
      <div class="mb-4">
        <p class="text-sm">
          Dit is een {{ recurringMapped }} herhalende activiteit. Wat wil je
          aanpassen?
        </p>
        <template>
          <radio-button
            :selected="selectedChangeDateOption"
            name="changeDate"
            value="all"
            label="Tijd aanpassen voor de hele reeks"
            @change="(val) => (selectedChangeDateOption = val)"
          />
          <radio-button
            :selected="selectedChangeDateOption"
            name="changeDate"
            value="single"
            label="Datum en tijd aanpassen voor een activiteit uit de reeks"
            @change="(val) => (selectedChangeDateOption = val)"
          />
        </template>
        <template v-if="selectedChangeDateOption === 'all'">
          <div class="flex">
            <div class="w-1/2 flex-0 mr-2 flex flex-column">
              <h2>Starttijd</h2>
              <div class="relative">
                <datetime
                  v-model="updatedSingleEventDate.startDateTime"
                  type="time"
                  :minute-step="15"
                  class="theme-green border border-gray-600 h-11 rounded-lg p-1 px-4"
                  input-class="w-100 h-100 text-center text-sm"
                  :phrases="{
                    ok: 'Ok',
                    cancel: 'Annuleer'
                  }"
                />
                <icon-base class="absolute left-2 top-2.5" size="24">
                  <icon-clock />
                </icon-base>
              </div>
            </div>
            <div class="w-1/2 flex-0 ml-2 flex flex-column">
              <h2>Eindtijd</h2>
              <div class="relative">
                <datetime
                  v-model="updatedSingleEventDate.endDateTime"
                  type="time"
                  :minute-step="15"
                  class="theme-green border border-gray-600 h-11 rounded-lg p-1 px-4"
                  input-class="w-100 h-100 text-center text-sm"
                  :phrases="{ ok: 'Ok', cancel: 'Annuleer' }"
                />
                <IconBase class="absolute left-2 top-2.5" size="24">
                  <IconClock />
                </IconBase>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="selectedChangeDateOption === 'single'">
          <div
            v-if="datesInPast"
            class="text-xs bg-yellow-100 text-yellow-900 p-2 rounded-md"
          >
            Let op: Je kunt alleen de activiteiten die in de toekomst liggen
            aanpassen
          </div>
          <h2>Activiteit</h2>
          <dropdown
            :disabled="false"
            :items="eventDates"
            empty-option="Kies activiteit"
            theme="rounded"
            @on-select="(val) => selectDateFromRange(val)"
          />
          <date-time-picker
            :disabled="!eventDateFromRange.id"
            title="Nieuwe datum"
            :date-time="eventDateFromRange.startDateTime"
            :start-date-time="eventDateFromRange.startDateTime"
            :end-date-time="eventDateFromRange.endDateTime"
            @change-date-time="
              (datetimeObj) => $emit('on-change-date-time', datetimeObj)
            "
          />
        </template>
      </div>
    </template>
  </edit-field>
</template>

<script>
import { DateTime } from 'luxon'
import data from '@/utils/data'

import DateTimePicker from '@/components/DateTimePicker'
import Dropdown from '@/components/Dropdown'
import EditField from '@/components/EditField'
import IconBase from '@/components/IconBase'
import IconClock from '@/components/icons/IconClock'
import RadioButton from '@/components/RadioButton'

export default {
  name: 'EventEditDate',
  components: {
    DateTimePicker,
    Dropdown,
    EditField,
    IconBase,
    IconClock,
    RadioButton
  },
  props: {
    event: {
      type: Object,
      required: true
    },
    isEditting: {
      type: String,
      default: undefined
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    singleEvent: {
      type: Boolean,
      default: false
    },
    singleEventDate: {
      type: Object,
      default: () => ({})
    },
    updatedSingleEventDate: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      eventDateFromRange: {},
      newDateFormat: DateTime.DATE_HUGE,
      selectedChangeDateOption: undefined
    }
  },
  computed: {
    currentDate: function () {
      if (this.event.dates && this.event.dates.length) {
        if (this.singleEvent) {
          const start = DateTime.fromISO(this.event.dates[0].startDateTime)
          const end = DateTime.fromISO(this.event.dates[0].endDateTime)
          return `${start.toFormat('ccc d LLL yyyy')} van ${start.toFormat(
            'HH:mm'
          )} - ${end.toFormat('HH:mm')}`
        } else {
          const start = DateTime.fromISO(this.event.dates[0].startDateTime)
          const end = DateTime.fromISO(
            this.event.dates[this.event.dates.length - 1].endDateTime
          )
          return `${start.toFormat('ccc d LLL yyyy')} - ${end.toFormat(
            'ccc d LLL yyyy'
          )}
          (${this.recurringMapped} herhaald)`
        }
      } else return undefined
    },
    recurringMapped: function () {
      return data.formatRecurring(this.event.recurring)
    },
    eventDates: function () {
      return this.event.dates
        .filter(
          (date) => DateTime.fromISO(date.startDateTime) > DateTime.local()
        )
        .map((date) => ({
          key: date.id,
          value: DateTime.fromISO(date.startDateTime).toLocaleString(
            DateTime.DATE_HUGE
          ),
          date: date.startDateTime
        }))
    },
    changeDateButtonIsDisabled: function () {
      return this.singleEvent
        ? false
        : !this.selectedChangeDateOption ||
            (this.selectedChangeDateOption === 'single' &&
              Object.keys(this.eventDateFromRange).length === 0)
    },
    datesInPast: function () {
      const dates = this.event.dates.filter(
        (date) => DateTime.fromISO(date.startDateTime) < DateTime.local()
      )
      return dates.length > 0
    },
    isSingle: function () {
      return this.singleEvent || this.selectedChangeDateOption === 'single'
    }
  },
  watch: {
    isLoading: function (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.selectedChangeDateOption = undefined
        this.eventDateFromRange = {}
      }
    }
  },
  methods: {
    selectDateFromRange: function (dateId) {
      const date = this.event.dates.find((date) => date.id === parseInt(dateId))
      if (date) {
        this.eventDateFromRange = {
          id: date.id,
          startDateTime: date.startDateTime,
          endDateTime: date.endDateTime
        }
      } else {
        this.eventDateFromRange = {}
      }
    },
    onSave() {
      this.$emit('on-save', {
        singleEvent: this.isSingle,
        selectedDateItem: this.isSingle
          ? this.singleEventDate
          : this.eventDateFromRange
      })
    }
  }
}
</script>
