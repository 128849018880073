<template>
  <div class="screen-event-view has-tab-bar">
    <app-bar
      title="Deelnemers"
      :show-back-button="true"
      :back-button-path="backPath"
    />
    <spinner-loader v-if="status === 'loading'" />

    <template v-else-if="participantList">
      <div class="container">
        <div class="row wrapper-tabs margin-bottom-15">
          <div class="col-4">
            <a
              href="#"
              class="btn btn-block"
              :class="[
                currentTab === 'wel' ? 'btn-green' : 'btn-white-border-green'
              ]"
              @click.prevent="setTab('wel')"
            >
              wel ({{ participantsYes.length }})
            </a>
          </div>
          <div class="col-4">
            <a
              href="#"
              class="btn btn-block"
              :class="[
                currentTab === '?' ? 'btn-green' : 'btn-white-border-green'
              ]"
              @click.prevent="setTab('?')"
            >
              ? ({{ participantsMaybe.length }})
            </a>
          </div>
          <div class="col-4">
            <a
              href="#"
              class="btn btn-block"
              :class="[
                currentTab === 'niet' ? 'btn-green' : 'btn-white-border-green'
              ]"
              @click.prevent="setTab('niet')"
            >
              niet ({{ participantsNo.length }})
            </a>
          </div>
        </div>

        <div v-if="dates && dates.length" class="form-select">
          <select
            v-model="selected"
            class="form-control"
            :style="{ border: borderSize + 'px solid ' + borderColor }"
            @change="setDate"
          >
            <option v-for="date in dates" :key="date.value" :value="date.value">
              {{ date.text }}
            </option>
          </select>
          <div
            class="select-arrow-background"
            :style="{ backgroundColor: selectArrowBackgroundColor }"
          />
          <div
            class="select-arrow"
            :style="{ borderTopColor: selectArrowColor }"
          />
        </div>

        <div v-if="currentTab === 'wel'">
          <template v-if="participantsYes.length > 0">
            <div class="wrapper-participant">
              <ul class="participant-list">
                <li
                  v-for="participant in participantsYes"
                  :key="participant.id"
                  class="participant-list-item"
                  @click.prevent="routeToParticipant(participant.id)"
                >
                  <template
                    v-if="
                      participant.avatarUrl === null ||
                      participant.avatarUrl === undefined
                    "
                  >
                    <round-icon
                      :size="75"
                      :border-size="0"
                      background-color="#E6E6E6"
                      icon-class="fal fa-user"
                    />
                  </template>
                  <template v-else>
                    <div
                      class="participant-avatar"
                      :style="{
                        backgroundImage: `url('${participant.avatarUrl}')`
                      }"
                    />
                  </template>
                  <div class="participant-name">
                    {{ `${participant.firstName} ${participant.lastName}` }}
                  </div>
                </li>
              </ul>
            </div>
          </template>
          <div v-else class="wrapper-participant">
            <p class="text-center">Er zijn geen deelnemers om te tonen</p>
          </div>
        </div>

        <div v-if="currentTab === '?'">
          <template v-if="participantsMaybe.length > 0">
            <div class="wrapper-participant">
              <ul class="participant-list">
                <li
                  v-for="participant in participantsMaybe"
                  :key="participant.id"
                  @click.prevent="routeToParticipant(participant.id)"
                >
                  <template
                    v-if="
                      participant.avatarUrl === null ||
                      participant.avatarUrl === undefined
                    "
                  >
                    <round-icon
                      :size="75"
                      :border-size="0"
                      background-color="#E6E6E6"
                      icon-class="fal fa-user"
                    />
                  </template>
                  <template v-else>
                    <div
                      class="participant-avatar"
                      :style="{
                        backgroundImage: `url('${participant.avatarUrl}')`
                      }"
                    />
                  </template>
                  <span class="participant-name">
                    {{ `${participant.firstName} ${participant.lastName}` }}
                  </span>
                </li>
              </ul>
            </div>
          </template>
          <div v-else class="wrapper-participant">
            <p class="text-center">Er zijn geen deelnemers om te tonen</p>
          </div>
        </div>

        <div v-if="currentTab === 'niet'">
          <template v-if="participantsNo.length > 0">
            <div class="wrapper-participant">
              <ul class="participant-list">
                <li
                  v-for="participant in participantsNo"
                  :key="participant.id"
                  @click.prevent="routeToParticipant(participant.id)"
                >
                  <template
                    v-if="
                      participant.avatarUrl === null ||
                      participant.avatarUrl === undefined
                    "
                  >
                    <round-icon
                      :size="75"
                      :border-size="0"
                      background-color="#E6E6E6"
                      icon-class="fal fa-user"
                    />
                  </template>
                  <template v-else>
                    <div
                      class="participant-avatar"
                      :style="{
                        backgroundImage: `url('${participant.avatarUrl}')`
                      }"
                    />
                  </template>
                  <span class="participant-name">
                    {{ `${participant.firstName} ${participant.lastName}` }}
                  </span>
                </li>
              </ul>
            </div>
          </template>
          <div v-else class="wrapper-participant">
            <p class="text-center">Er zijn geen deelnemers om te tonen</p>
          </div>
        </div>
      </div>
    </template>

    <tab-bar selected="search" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import RoundIcon from '@/components/RoundIcon'
import SpinnerLoader from '@/components/SpinnerLoader'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import {
  EVENT_DATES_GET,
  EVENT_DATES_GET_ERROR,
  EVENT_DATES_GET_PARTICIPANTS
} from '@/store/actions/date'
import api from '@/utils/api'

export default {
  name: 'EventParticipantAttendance',
  components: {
    AppBar,
    TabBar,
    RoundIcon,
    SpinnerLoader
  },
  data() {
    return {
      currentTab: 'wel', // ? niet
      participantsYes: [],
      participantsNo: [],
      participantsMaybe: [],
      selected: '',
      borderSize: 2,
      selectArrowColor: '#FFF',
      selectArrowBackgroundColor: '#009B6D',
      borderColor: '',
      routeName: this.$route.name,
      securityHash: this.$route.params.securityHash,
      dateId: this.$route.params.dateId,
      eventId: this.$route.params.id,
      userIsOwner: false,
      dates: [],
      backPath: '/'
    }
  },

  computed: {
    ...mapGetters(['authId', 'participantList']),
    ...mapState({
      eventDate: (state) => state.date.eventDate,
      status: (state) => state.date.status,
      dateParticipants: (state) => state.date.participants
    })
  },
  watch: {
    participantList(list) {
      this.participantsYes = list.yes || []
      this.participantsNo = list.no || []
      this.participantsMaybe = list.maybe || []
    },
    eventDate: function (event) {
      this.userIsOwner = event.ownerId === parseInt(this.authId)
      this.dates =
        event.allDates &&
        event.allDates.map((date) => ({
          text: moment(date.startDateTime).format('dd D MMM HH:mm'),
          value: date.id
        }))
      this.selected = event.id
    }
  },
  created: async function () {
    let dateId = this.dateId
    if (
      this.routeName === 'EventParticipantAttendance' &&
      this.eventId &&
      this.securityHash
    ) {
      if (!dateId) {
        const response = await api.get(
          `event/${this.eventId}?securityHash=${this.securityHash}`
        )
        if (response && response.data && response.data.nextDate) {
          dateId = response.data.nextDate.id
        }
      }
      this.$store.dispatch(EVENT_DATES_GET, {
        dateId,
        securityHash: this.securityHash
      })
      this.backPath = `/events/${dateId}/${this.securityHash}`
      this.$router.push(`/events/${dateId}/${this.securityHash}/participants`)
    } else if (dateId) {
      this.backPath = `/events/${dateId}/${this.securityHash}`
      this.$store.dispatch(EVENT_DATES_GET, {
        dateId,
        securityHash: this.securityHash
      })
    } else {
      this.$store.commit(EVENT_DATES_GET_ERROR)
    }
  },
  methods: {
    setTab: function (tabName) {
      this.currentTab = tabName
    },
    setDate: function (event) {
      this.selected = event.target.value
      this.$store.dispatch(EVENT_DATES_GET_PARTICIPANTS, {
        dateId: event.target.value
      })
    },
    formatTime: function (timestamp, timezoneOffset) {
      const eventCreatedInSummer = timezoneOffset === 2
      return moment(timestamp).isDST()
        ? moment(timestamp)
            .subtract(eventCreatedInSummer ? 0 : 1, 'hours')
            .locale('nl')
            .format('dd D MMM HH:mm')
        : moment(timestamp)
            .add(eventCreatedInSummer ? 1 : 0, 'hours')
            .locale('nl')
            .format('dd D MMM HH:mm')
    },
    routeToParticipant: function (participantId) {
      this.$router.push(`/profile-view/${participantId}`)
    },
    paidIconStyle() {
      return {
        fontSize: this.size * 0.6 + 'px',
        color: '#fff'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

h1.event-title {
  margin-bottom: 0;
  margin-top: 1.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.wrapper-tabs {
  margin-top: 15px;
}

@media (max-width: 380px) {
  .wrapper-tabs a {
    font-size: 1rem;
  }
}

@media (max-width: 325px) {
  .wrapper-tabs a {
    font-size: 0.75rem;
    padding-left: 3px;
    padding-right: 3px;
  }
}

.wrapper-tabs div:first-child {
  padding-right: 7.5px;
}

.wrapper-tabs div:last-child {
  padding-left: 7.5px;
}

.page-title {
  margin: 30px 0;
}

.form-select {
  position: relative;
  display: inline-block;
  width: 100%;
}
.form-select select {
  display: inline-block;
  width: 100%;
  outline: 0;
  border: 1px solid $ipBlack;
  background: $ipWhite;
  color: $ipBlack;
  font-size: 1.25rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.form-select select::-ms-expand {
  display: none;
}
.form-select select:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.select-arrow {
  position: absolute;
  top: 19px;
  right: 15px;
  width: 0;
  height: 0;
  pointer-events: none;
  border-style: solid;
  border-width: 12px 10px 0 10px;
  border-color: $ipWhite transparent transparent transparent;
}
.select-arrow-background {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: $ipGreen;
  pointer-events: none;
}

.wrapper-participant {
  margin-top: 30px;
}
.participant-list {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.participant-list li {
  display: flex;
  margin: 15px 0 30px 0;
  text-align: left;
  width: 100%;
  align-items: center;
}
.participant-list li .participant-avatar {
  display: inline-block;
  width: 75px;
  height: 75px;
  background-size: cover;
  background-position: center;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  padding: 0;
  outline: 0;
  @include border-radius(75px);
}
.participant-list li a {
  text-decoration: none;
  color: inherit;
}
.participant-list li .participant-name {
  flex: 1;
  font-size: 1rem;
  margin-left: 1rem;
}
.to-participants {
  margin-bottom: 30px;
}
@media (max-width: 320px) {
  .participant-list li {
    width: 85px;
  }
  .participant-list li .participant-name {
    padding: 0 5px;
  }
}
.status-icon {
  display: block;
  float: right;
  height: 24px;
  border-radius: 12px;
  background-color: rgb(230, 230, 230);
  color: white;
  font-size: 0.875rem;
  padding: 2px 0.5rem;
  margin: 0 auto;
}
.status-icon.paid {
  background-color: $ipGreen;
}
.status-icon.not-paid {
  background-color: rgb(230, 230, 230);
  color: #212529;
}
.icon {
  margin-right: 0.25rem;
}
</style>
