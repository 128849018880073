<template>
  <div>
    <app-bar
      :title="eventDate.name || 'Aanmelden'"
      :show-back-button="true"
      :back-button-path="`/events/${dateId}/${securityHash}`"
    ></app-bar>
    <spinner-loader v-if="loading"></spinner-loader>
    <template v-else-if="Object.keys(eventDate).length">
      <div class="container pb-28">
        <h1 v-if="!userIsParticipant">Leuk dat je meedoet!</h1>
        <h1 v-else>Wil je je aanwezigheid aanpassen?</h1>
        <div class="border border-green-600 rounded-lg px-4 pb-2">
          <p class="text-base pt-2 mb-2">
            {{ dateAndTimeSelectedEvent }}
          </p>
          <template
            v-if="eventDate.isFull && attendanceOfThisEventDate !== 'yes'"
          >
            <div class="my-4">
              <span
                class="text-xs py-1 px-2 border border-red-600 rounded-lg text-red-600"
              >
                Helaas, deze datum is vol
              </span>
              <p class="text-xs text-gray-600 mt-4">
                Je kunt je niet meer aanmelden voor deze datum, maar nog wel op
                misschien zetten voor het geval er nog een plekje vrijkomt
              </p>
            </div>
          </template>
          <template>
            <div>
              <radio-button
                name="selectedDate"
                value="yes"
                :selected="changedDateAttendance"
                label="Ik ben er bij!"
                :disabled="
                  eventDate.isFull && attendanceOfThisEventDate !== 'yes'
                "
                @change="(val) => setAttendance(eventDate.id, val)"
              />
              <radio-button
                name="selectedDate"
                value="maybe"
                :selected="changedDateAttendance"
                class="mb-2"
                label="Ik weet het nog niet zeker"
                type="maybe"
                @change="(val) => setAttendance(eventDate.id, val)"
              />
              <radio-button
                v-if="userIsParticipant"
                name="selectedDate"
                value="no"
                :selected="changedDateAttendance"
                class="mb-2"
                label="Ik ben er niet bij"
                type="no"
                @change="(val) => setAttendance(eventDate.id, val)"
              />
            </div>
          </template>
        </div>

        <div v-if="otherDates.length" class="flex mt-4">
          <h2 class="flex-1">Aanmelden voor meer dagen?</h2>
          <toggle
            :value="showAllDates"
            @toggle="(val) => (showAllDates = val)"
          />
        </div>
        <div v-if="showAllDates" class="my-4">
          <p class="text-sm">
            Geef hieronder aan op welke dagen je er nog meer bij bent
          </p>
          <div class="flex justify-end">
            <div class="flex flex-row items-center text-sm text-center mb-4">
              <div class="w-11">Ja</div>
              <div class="w-11">?</div>
              <div class="w-11">Nee</div>
            </div>
          </div>
          <div v-for="(date, index) in otherDates" :key="index">
            <attendance-switch
              :id="date.id"
              :value="setValue(date)"
              :current-attendance="date.currentAttendance"
              :start-date-time="date.startDateTime"
              :end-date-time="date.endDateTime"
              :is-full="date.isFull"
              class="mb-4"
              @setAttendance="setAttendance"
            />
          </div>
        </div>

        <template v-if="activeCard">
          <p class="text-sm">
            <template v-if="ownedCreditcardForThisEvent">
              <div class="text-sm my-6">
                Je hebt een strippenkaart voor deze activiteit en hebt nog
                <strong>
                  {{ ownedCreditcardForThisEvent.remaining }} strippen
                </strong>
                over.
              </div>
            </template>
            <template v-else>
              <h2>Strippenkaart</h2>
              <div v-if="eventDate.creditcardOnly">
                Voor dit event moet je een strippenkaart aanschaffen om mee te
                kunnen doen. Je hoeft dan niet elke keer opnieuw een iDeal
                betaling te doen. Handig!
              </div>
              <div v-else>
                Door een strippenkaart aan te schaffen hoef je niet elke keer
                opnieuw een iDeal betaling te doen maar reken je gewoon per
                strip af. Handig!
              </div>
              <div class="mt-4 text-sm border border-gray-300 p-4 rounded-md">
                <p class="text-base mb-4">{{ activeCard.name }}</p>
                <div class="flex">
                  <div class="flex-1 font-semibold">
                    Prijs van de strippenkaart
                  </div>
                  <div>
                    {{ $n(activeCard.cost, 'currency') }}
                  </div>
                </div>
                <div class="flex mt-2">
                  <div class="flex-1 font-semibold">
                    Aantal keer te gebruiken
                  </div>
                  <div>{{ activeCard.times_to_use }} keer</div>
                </div>
              </div>
              <button
                class="button button-primary w-full mt-6"
                @click="goToPayment(PURCHASE_CREDITCARD)"
              >
                Koop strippenkaart
              </button>
            </template>
          </p>
        </template>
      </div>
      <div class="border-t border-gray-300 bottom-0 fixed w-full h-20 bg-white">
        <div
          class="flex items-center p-4 max-w-screen-sm pl-6 pr-6 my-0 mx-auto"
        >
          <p class="flex-1 flex items-center">
            <template
              v-if="eventDate.creditcardOnly && !ownedCreditcardForThisEvent"
            >
              <span class="mt-3">
                Koop een strippenkaart om mee te kunnen doen
              </span>
            </template>
            <template v-else>
              <span class="mr-2">
                {{
                  !ownedCreditcardForThisEvent && !creditsToReturn
                    ? amountToPay >= 0
                      ? 'Te betalen:'
                      : 'Te ontvangen:'
                    : creditsToPay >= 0
                    ? 'Aantal strippen:'
                    : 'Strippen retour:'
                }}
              </span>
              <span class="font-semibold">
                <template
                  v-if="!ownedCreditcardForThisEvent && !creditsToReturn"
                >
                  {{ $n(Math.abs(amountToPay), 'currency') }}
                </template>
                <template v-else>{{ Math.abs(creditsToPay) }}</template>
              </span>
              <span
                v-if="amountToPay !== 0 || creditsToPay !== 0"
                @click="showPriceInfoModal = true"
              >
                <icon-base class="ml-2" size="20">
                  <icon-info />
                </icon-base>
              </span>
            </template>
          </p>
          <button
            v-if="!(eventDate.creditcardOnly && !ownedCreditcardForThisEvent)"
            :disabled="!changedDates.length"
            class="button button-primary"
            @click="saveAttendance"
          >
            {{
              amountToPay > 0 && !ownedCreditcardForThisEvent
                ? 'Betalen'
                : 'Opslaan'
            }}
          </button>
          <button
            v-else
            class="button button-primary"
            @click="goToPayment(PURCHASE_CREDITCARD)"
          >
            Koop
          </button>
        </div>
      </div>
    </template>

    <div v-else class="container pb-28">
      <h1>Oeps, er gaat iets fout!</h1>
    </div>

    <modal
      :show="showConfirmModal"
      :show-close-icon="true"
      @close="
        () => {
          showConfirmModal = false
          $router.push(`/events/${dateId}/${securityHash}`)
        }
      "
    >
      <template v-slot:header>Gelukt!</template>
      <p>Je hebt je aanwezigheid aangepast voor deze activiteit!</p>
    </modal>

    <modal
      :show="showErrorModal"
      :show-close-icon="true"
      @close="
        () => {
          showErrorModal = false
          $router.push(`/events/${dateId}/${securityHash}`)
        }
      "
    >
      <template v-slot:header>Oeps!</template>
      <p>
        Er is iets fout gegaan... Ga terug naar het event en probeer het opnieuw
      </p>
    </modal>

    <modal
      :show="showPriceInfoModal"
      :show-close-icon="true"
      @close="
        () => {
          showPriceInfoModal = false
        }
      "
    >
      <template v-slot:header>Uitleg</template>
      <template v-if="ownedCreditcardForThisEvent">
        <p v-if="creditsToPay < 0">
          Je hebt een strippenkaart voor deze activiteit. Je meldt je af voor
          {{ $tc('activity-count', creditsToPayAbs) }}, de strippen worden terug
          gezet op je strippenkaart
        </p>
        <p v-else>
          Je hebt een strippenkaart voor deze activiteit. Je meldt je aan voor
          {{ $tc('activity-count', creditsToPayAbs) }}, de strippen worden van
          je tegoed afgehaald
        </p>
      </template>
      <template v-else-if="creditsToReturn">
        Je meldt je af voor
        {{ $tc('activity-count', creditsToPayAbs) }} waarvoor je met een
        strippenkaart hebt betaald. De strippen worden terug gezet op je
        strippenkaart.
      </template>
      <template v-else>
        <p v-if="timesToPay < 0">
          Je meldt je af voor
          {{ $tc('activity-count', timesToPayAbs) }}, we boeken je inschrijfgeld
          min de terugboekkosten naar je terug
        </p>
        <p v-else>
          Je meldt je aan voor
          {{ $tc('activity-count', creditsToPayAbs) }} en een activiteit kost
          {{ $n(eventDate.costFeeIncluded, 'currency') }} per keer
        </p>
      </template>
    </modal>

    <modal
      :show="showPaymentFeedbackModal"
      :show-close-icon="true"
      @close="
        () => {
          $router.push($route.path)
        }
      "
    >
      <template v-slot:header>Gelukt!</template>
      <p>
        Je hebt een strippenkaart aangeschaft voor deze activiteit. Meld je nu
        makkelijk en snel aan.
      </p>
    </modal>

    <modal
      :show="showModalCreditsDone"
      :show-close-icon="true"
      @close="showModalCreditsDone = false"
    >
      <template v-slot:header>Niet genoeg strippen</template>
      <p>
        Je hebt niet genoeg strippen om je voor {{ creditsToPay }} activiteiten
        aan te melden. Maak nu eerst deze strippenkaart op en koop vervolgens
        een nieuwe strippenkaart als je je voor meer activiteiten wil aanmelden.
      </p>
    </modal>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import Toggle from '@/components/Toggle'
import { mapState } from 'vuex'
import { EVENT_DATES_GET } from '@/store/actions/date'
import AttendanceSwitch from '@/components/AttendanceSwitch'
import {
  EVENT_LOGGED_IN_USER_ATTENDANCE,
  EVENT_STORE_DATES_TO_UPDATE
} from '@/store/actions/event'
import RadioButton from '@/components/RadioButton'
import IconInfo from '@/components/icons/IconInfo'
import IconBase from '@/components/IconBase'
import { PARTICIPANT_GET_CREDITCARD_ON_EVENT } from '@/store/actions/participant'
import { EVENT_GET_CREDITCARDS } from '@/store/actions/event'
import {
  PURCHASE_CREDITCARD,
  SINGLE_PAYMENT,
  CREDIT_PAYMENT,
  REFUND
} from '@/utils/constants'
import { PAYMENT_SET_TYPE } from '@/store/actions/payment'
import { EVENT_UPDATE_ATTENDANCE } from '@/store/actions/event'
import Modal from '../../components/Modal.vue'
import { OPTION_GET } from '../../store/actions/option'
import { CREDITCARD_GET_GROUP } from '../../store/actions/creditcard'

export default {
  name: 'EventSignup',
  components: {
    AppBar,
    SpinnerLoader,
    Toggle,
    AttendanceSwitch,
    RadioButton,
    IconBase,
    IconInfo,
    Modal
  },
  data() {
    return {
      showAllDates: this.$route.query.showAllDates === 'true',
      dateId: parseInt(this.$route.params.dateId),
      securityHash: this.$route.params.securityHash,
      datesToUpdate: [],
      PURCHASE_CREDITCARD: PURCHASE_CREDITCARD,
      SINGLE_PAYMENT: SINGLE_PAYMENT,
      CREDIT_PAYMENT: CREDIT_PAYMENT,
      showConfirmModal: false,
      showErrorModal: false,
      showPriceInfoModal: false,
      showPaymentFeedbackModal: false,
      paymentId: this.$route.query.pid,
      showModalCreditsDone: false,
      showRefundModal: false,
      refundObject: {}
    }
  },
  computed: {
    ...mapState({
      eventDate: (state) => state.date.eventDate,
      currentAttendance: (state) => state.event.attendance,
      participantCreditcards: (state) => state.participant.creditcards,
      creditcards: (state) => state.event.creditcards,
      refundCosts: (state) => state.option.options.payments_refund_fee,
      loading: (state) =>
        state.event.status === 'loading' || state.date.status === 'loading'
    }),
    action: function () {
      return this.$route.query.action
    },
    otherDates: function () {
      return (
        this.eventDate?.allDates
          ?.filter(
            (date) =>
              DateTime.fromISO(date.startDateTime) > DateTime.local() &&
              date.id !== this.eventDate.id
          )
          .map((od) => ({
            ...od,
            currentAttendance: this.currentAttendance.find(
              (ca) => ca.dateId === od.id
            )?.attendance
          })) || []
      )
    },
    dateAndTimeSelectedEvent: function () {
      return DateTime.fromISO(this.eventDate.startDateTime).toFormat(
        `DDDD t - ${this.eventDate.endDateTimeFormatted}`
      )
    },
    activeCard: function () {
      return this.eventDate &&
        this.creditcards[this.eventDate.eventId] &&
        (parseFloat(this.eventDate.costFeeIncluded) ||
          this.eventDate.creditcardOnly)
        ? this.creditcards[this.eventDate.eventId][0]
        : null
    },
    ownedCreditcardForThisEvent: function () {
      return (
        this.eventDate && this.participantCreditcards[this.eventDate.eventId]
      )
    },
    timesToPay: function () {
      return this.changedDates.length
        ? this.changedDates.reduce((number, date) => {
            if (date.attendance === 'yes' && date.payment_status !== 'paid') {
              return number + 1
            } else if (
              date.attendance === 'no' &&
              date.payment_status === 'paid'
            ) {
              return number - 1
            }
            return number
          }, 0)
        : 0
    },
    timesToPayAbs: function () {
      return Math.abs(this.timesToPay)
    },
    amountToPay: function () {
      return this.timesToPay > 0
        ? this.timesToPay * parseFloat(this.eventDate.costFeeIncluded)
        : this.timesToPay < 0
        ? this.timesToPay *
          (parseFloat(this.eventDate.costFeeIncluded) - this.refundCosts)
        : 0
    },
    creditsToPay: function () {
      return this.changedDates.length
        ? this.changedDates.reduce((credits, date) => {
            if (date.attendance === 'yes' && date.payment_status !== 'credit') {
              return credits + 1
            } else if (
              date.attendance === 'no' &&
              date.payment_status === 'credit'
            ) {
              return credits - 1
            }
            return credits
          }, 0)
        : 0
    },
    creditsToPayAbs: function () {
      return Math.abs(this.creditsToPay)
    },
    creditsToReturn: function () {
      return this.creditsToPay < 0
    },
    changedDates: function () {
      return this.datesToUpdate.reduce((list, dateToUpdate) => {
        const current = this.currentAttendance.find(
          (c) => c.dateId === dateToUpdate.dateId
        )
        return (!current || current.attendance !== dateToUpdate.attendance) &&
          !dateToUpdate.isFull
          ? [...list, dateToUpdate]
          : list
      }, [])
    },
    userIsParticipant: function () {
      return (
        this.currentAttendance.findIndex((c) => c.dateId === this.dateId) >= 0
      )
    },
    changedDateAttendance: function () {
      const currentDate = this.datesToUpdate.find(
        (cd) => cd.dateId === this.dateId
      )
      return currentDate || this.eventDate.isFull
        ? currentDate?.attendance || null
        : 'yes'
    },
    attendanceOfThisEventDate: function () {
      return this.currentAttendance.find((c) => c.dateId === this.dateId)
        ?.attendance
    }
  },
  watch: {
    action(action) {
      this.showPaymentFeedbackModal = action === 'payment'
    },
    eventDate(event) {
      this.$store.dispatch(EVENT_LOGGED_IN_USER_ATTENDANCE, {
        eventId: event.eventId
      })
      this.$store.dispatch(PARTICIPANT_GET_CREDITCARD_ON_EVENT, {
        eventId: event.eventId
      })
      this.$store.dispatch(EVENT_GET_CREDITCARDS, this.eventDate.eventId)
      if (this.eventDate.groupId) {
        this.$store.dispatch(CREDITCARD_GET_GROUP, {
          groupId: this.eventDate.groupId
        })
      }
    },
    currentAttendance(attendance) {
      const current = attendance.find((a) => a.dateId === this.dateId)
      this.datesToUpdate = [
        ...(!current && !this.eventDate.isFull
          ? [
              {
                dateId: this.dateId,
                attendance: 'yes',
                payment_status: null
              }
            ]
          : []),
        ...attendance.filter(
          (att) =>
            !this.datesToUpdate.map((date) => date.dateId).includes(att.dateId)
        )
      ]
    },
    changedDates(dates) {
      this.$store.commit(EVENT_STORE_DATES_TO_UPDATE, dates)
    }
  },
  created: function () {
    this.showPaymentFeedbackModal = this.action === 'payment'
    this.$store.dispatch(EVENT_DATES_GET, {
      dateId: this.dateId,
      securityHash: this.securityHash
    })
    this.$store.dispatch(OPTION_GET, 'payments_refund_fee')
  },
  methods: {
    formatDateAndTime(date) {
      return DateTime.fromISO(date).toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      )
    },
    formatTime(date) {
      return DateTime.fromISO(date).toLocaleString(DateTime.TIME_24_SIMPLE)
    },
    setAttendance(id, value) {
      const current = this.currentAttendance.find((date) => date.dateId === id)
      this.datesToUpdate = [
        ...this.datesToUpdate.filter((date) => date.dateId !== id),
        {
          ...(current ? current : { dateId: id }),
          attendance: value
        }
      ].sort((a, b) => a.dateId - b.dateId)
      // handle refund immediately
      // if (
      //   current?.attendance === 'yes' &&
      //   current?.payment_status === 'paid' &&
      //   (value === 'no' || value === 'maybe')
      // ) {
      //   this.refundObject = { id, value, payment_type: current.payment_type }
      //   this.showRefundModal = true
      // }
    },
    setValue(date) {
      const dateFound = this.datesToUpdate.find((d) => date.id === d.dateId)
      return dateFound ? dateFound.attendance : undefined
    },
    async goToPayment(paymentType) {
      this.$store.commit(PAYMENT_SET_TYPE, paymentType)
      if (paymentType === CREDIT_PAYMENT) {
        if (
          parseInt(this.ownedCreditcardForThisEvent.remaining) <
          this.creditsToPay
        ) {
          this.showModalCreditsDone = true
        } else {
          const response = await this.$store.dispatch(EVENT_UPDATE_ATTENDANCE, {
            eventId: this.eventDate.eventId,
            dateId: this.dateId,
            securityHash: this.securityHash,
            creditcardId: this.ownedCreditcardForThisEvent.creditcard_id,
            dates: this.changedDates
          })
          if (response && response.status === 'success') {
            this.$router.push(
              `/events/${this.dateId}/${this.securityHash}?action=credits_used`
            )
          } else if (response && response.redirectUrl) {
            window.location.href = response.redirectUrl
          } else {
            this.showErrorModal = true
          }
        }
      } else if (paymentType === REFUND) {
        const response = await this.$store.dispatch(EVENT_UPDATE_ATTENDANCE, {
          eventId: this.eventDate.eventId,
          dateId: this.dateId,
          securityHash: this.securityHash,
          dates: this.changedDates
        })
        if (response && response.status === 'success') {
          this.$router.push(
            `/events/${this.dateId}/${this.securityHash}?action=refund`
          )
        } else if (response && response.redirectUrl) {
          window.location.href = response.redirectUrl
        } else {
          this.showErrorModal = true
        }
      } else {
        this.$router.push(
          `/events/${this.dateId}/signup/${this.securityHash}/payment`
        )
      }
    },
    async saveAttendance() {
      if (this.amountToPay > 0) {
        this.goToPayment(
          this.ownedCreditcardForThisEvent ? CREDIT_PAYMENT : SINGLE_PAYMENT
        )
      } else if (this.amountToPay < 0) {
        this.goToPayment(REFUND)
      } else {
        const response = await this.$store.dispatch(EVENT_UPDATE_ATTENDANCE, {
          eventId: this.eventDate.eventId,
          dateId: this.dateId,
          securityHash: this.securityHash,
          dates: this.changedDates
        })
        if (response && response.status === 'success') {
          // TODO: fire GA event for all 'yes' attendances
          this.showConfirmModal = true
        } else {
          this.showErrorModal = true
        }
      }
    }
  },
  metaInfo() {
    return {
      title: `Inviplay`
    }
  }
}
</script>
