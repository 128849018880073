<template>
  <div class="screen">
    <div class="header default">
      <AppBar
        :title="
          isLoading
            ? 'Even geduld...'
            : updateSuccess
            ? 'Bedankt!'
            : 'Er ging iets fout...'
        "
        :show-back-button="false"
      />
    </div>

    <div v-if="!isLoading" class="container">
      <template v-if="action === 'paid'">
        <template v-if="updateSuccess">
          <div class="page-title">
            We hebben je betaling ontvangen en je aanwezigheid doorgegeven!
          </div>
        </template>
        <template v-else>
          <div class="page-title">
            Er is iets fout gegaan met de betaling en je bent nog niet aangemeld
            voor deze activiteit. Ga terug en probeer het nog een keer.
          </div>
        </template>
      </template>
      <template v-else-if="action === 'refunded'">
        <template v-if="updateSuccess">
          <div class="page-title">
            Je aanwezigheid is bijgewerkt en je betaling wordt binnen enkele
            werkdagen teruggeboekt!
          </div>
        </template>
        <template v-else>
          <div class="page-title">
            Je bent wel afgemeld voor de activiteit, maar er is iets fout gegaan
            met de terugbetaling. Stuur een mailtje naar info@inviplay.nl en we
            maken het z.s.m. in orde
          </div>
        </template>
      </template>
      <template v-else>
        <template v-if="updateSuccess">
          <div class="page-title">Je aanwezigheid is bijgewerkt!</div>
        </template>
        <template v-else>
          <div class="page-title">
            Er is iets fout gegaan tijdens het bijwerken van je aanwezigheid.
            Probeer het nog een keer of stuur een mailtje naar info@inviplay.nl,
            dan kijken we even met je mee
          </div>
        </template>
      </template>
      <div>
        <button
          class="button button-secondary w-full mt-10"
          @click="routeToActivity()"
        >
          terug naar activiteit
        </button>
      </div>
    </div>
    <tab-bar />
    <SpinnerLoader v-if="isLoading" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import {
  PAYMENT_RESET_STATE,
  PAYMENT_GET_STATUS
} from '../store/actions/payment'
import { PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER } from '../store/actions/participant_date'
import { DATE_LIST_BY_EVENT_ID } from '../store/actions/date'

export default {
  name: 'ThankYouPage',
  components: {
    AppBar,
    TabBar,
    SpinnerLoader
  },
  data() {
    return {
      action: this.$route.params.action,
      eventId: this.$route.params.eventId,
      dateId: this.$route.params.dateId,
      securityHash: this.$route.params.securityHash,
      numberOfRepeats: 0,
      isLoading: true,
      updateSuccess: false,
      mailIsSend: false
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      paymentStatus: (state) => state.payment.mollie.status,
      dateListByEvent: (state) => state.date.dateListByEvent,
      // paymentUpdates: (state) => state.payment.mollie.updates,
      readyToSendEmail() {
        return (
          this.dateListByEvent, this.isLoading, this.updateSuccess, Date.now()
        )
      }
    })
  },
  watch: {
    paymentStatus(payment) {
      if (payment) {
        if (payment.status === 'open') {
          let repeatGetStatus
          if (this.numberOfRepeats < 5) {
            repeatGetStatus = setTimeout(() => {
              this.$store.dispatch(PAYMENT_GET_STATUS, {
                eventId: this.eventId
              })
              this.numberOfRepeats++
            }, 1000)
          } else {
            clearTimeout(repeatGetStatus)
            this.isLoading = false
          }
        } else {
          this.isLoading = false
        }
      }
    },
    isLoading(isLoading) {
      if (!isLoading) {
        switch (this.action) {
          case 'paid':
            if (this.paymentStatus && this.paymentStatus.status === 'paid') {
              this.updateSuccess = true
            } else {
              this.updateSuccess = false
            }
            this.$store.commit(PAYMENT_RESET_STATE)
            break
          case 'refunded':
            this.updateSuccess = true
            this.$store.commit(PAYMENT_RESET_STATE)
            break
          case 'settled':
            this.updateSuccess = true
            this.$store.commit(PAYMENT_RESET_STATE)
            break
          case 'saved':
            this.updateSuccess = true
            this.$store.commit(PAYMENT_RESET_STATE)
            break
          default:
            this.updateSuccess = false
        }
      }
    },
    readyToSendEmail() {
      if (
        !this.isLoading &&
        this.updateSuccess &&
        this.dateListByEvent.length &&
        !this.mailIsSend
      ) {
        this.$store.dispatch(PARTICIPANT_DATE_SEND_EMAIL_AFTER_REGISTER, {
          dateId: this.dateId || this.dateListByEvent[0].id,
          participantId: this.authId
        })
        this.$gtag.event('Event', 'subscribe')
        this.mailIsSend = true
      }
    }
  },
  created() {
    this.$store.dispatch(DATE_LIST_BY_EVENT_ID, this.eventId)
    if (this.action === 'paid') {
      this.$store.dispatch(PAYMENT_GET_STATUS, { eventId: this.eventId })
    } else if (this.action === 'refunded') {
      this.isLoading = false
    } else {
      this.isLoading = false
    }
  },
  methods: {
    routeToActivity: function () {
      this.$router.push(`/events/${this.dateId}/${this.securityHash}`)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
.screen .page-title {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  color: $ipGreen;
  margin-top: 45px;
}
/*---- Header default ----*/
.header.default {
  /* height: 125px; */
  background-color: $ipGreen;
}
.text-on-bottom {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  padding: 0 30px;
}
.terms {
  font-size: 0.75rem;
}
</style>
