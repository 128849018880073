<template>
  <div>
    <div v-if="showHeader" class="flex justify-end">
      <div class="flex flex-row items-center text-sm text-center mb-4">
        <div class="w-11">Ja</div>
        <div class="w-11">?</div>
        <div class="w-11">Nee</div>
      </div>
    </div>
    <div class="flex justify-between items-center">
      <div class="">
        {{ date }} {{ startTime }}-{{ endTime }}
        <template v-if="isFull && currentAttendance !== 'yes'">
          <span
            class="text-xs py-1 px-2 border border-red-600 rounded-lg text-red-600"
          >
            datum vol
          </span>
        </template>
      </div>
      <div class="flex flex-row items-center text-center justify-items-center">
        <template>
          <radio-button
            class="mt-0 mb-0 w-11 px-1.5"
            :name="`attendance-${id}`"
            value="yes"
            :selected="value"
            :size="7"
            :disabled="isFull && currentAttendance !== 'yes'"
            @change="(val) => $emit('setAttendance', id, val)"
          />
          <radio-button
            class="mt-0 mb-0 w-11 px-1.5"
            :name="`attendance-${id}`"
            value="maybe"
            :selected="value"
            :size="7"
            type="maybe"
            @change="(val) => $emit('setAttendance', id, val)"
          />
          <radio-button
            class="mt-0 mb-0 w-11 px-1.5"
            :name="`attendance-${id}`"
            value="no"
            :selected="value"
            :size="7"
            type="no"
            @change="(val) => $emit('setAttendance', id, val)"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import RadioButton from './RadioButton.vue'

export default {
  name: 'AttendanceSwitch',
  components: { RadioButton },
  props: {
    id: {
      type: Number,
      required: true
    },
    startDateTime: {
      type: String,
      required: true
    },
    endDateTime: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: undefined
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    currentAttendance: {
      type: String,
      default: undefined
    },
    isFull: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    date: function () {
      return DateTime.fromISO(this.startDateTime).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      )
    },
    startTime: function () {
      return DateTime.fromISO(this.startDateTime).toLocaleString(
        DateTime.TIME_SIMPLE
      )
    },
    endTime: function () {
      return DateTime.fromISO(this.endDateTime).toLocaleString(
        DateTime.TIME_SIMPLE
      )
    }
  },
  created: function () {},
  methods: {}
}
</script>
