<script>
import { ACTIVITY_LIST } from '@/store/actions/activity'
import { EVENT_EDIT, EVENT_EDIT_REQUEST_COMPLETED } from '@/store/actions/event'
import { mapState } from 'vuex'

export default {
  name: 'EventEditLoad',
  computed: {
    ...mapState({
      activityList: (state) => state.activity.activityList,
      eventEdit: (state) => state.event.eventEdit,
      eventEditRequestCompleted: (state) =>
        state.event.eventEditRequestCompleted
    })
  },
  watch: {
    eventEdit() {
      // Event is loaded redirect back to page
      if (this.$route.params.id && this.$route.query.returnPage) {
        this.$router.push(
          `/event-edit/${this.$route.params.id}/${this.$route.query.returnPage}`
        )
      } else {
        this.$router.push(`/event-edit/${this.$route.params.id}`)
      }
    }
  },
  created() {
    this.$store.dispatch(ACTIVITY_LIST)
    this.$store.commit(EVENT_EDIT_REQUEST_COMPLETED, false)
    this.$store.dispatch(EVENT_EDIT, this.$route.params.id)
  }
}
</script>

<style scoped></style>
