<template>
  <div class="has-tab-bar">
    <app-bar title="" :show-back-button="true" />
    <spinner-loader v-if="loading" />
    <div class="container">
      <h1>EventAllDates</h1>
    </div>
    <tab-bar selected="activities" />
  </div>
</template>

<script>
import AppBar from '@/components/AppBar'
import SpinnerLoader from '@/components/SpinnerLoader'
import TabBar from '@/components/TabBar'
import { mapState } from 'vuex'

export default {
  name: 'EventAllDates',
  components: { AppBar, SpinnerLoader, TabBar },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({})
  },
  watch: {},
  created: function () {},
  methods: {}
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
</style>
