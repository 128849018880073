<template>
  <edit-field
    id="editLocation"
    title="Locatie"
    :disabled="isEditting && isEditting !== 'editLocation'"
    :is-loading="isLoading"
    :value="`${location.name} (${location.adress}, ${location.city})`"
    :hide-save-button="true"
    @edit="(id) => $emit('on-edit', id)"
    @cancel="
      () => {
        newLocation = undefined
        oldLocation = undefined
      }
    "
  >
    <location-picker
      :edit="true"
      :is-loading="isLoading"
      :previous-locations="previousLocations"
      :new-location="newLocation"
      :old-location="oldLocation"
      @save-location="saveLocation"
      @set-previous-location="setPreviousLocation"
      @reload-locations="$emit('reload-locations')"
    />
  </edit-field>
</template>

<script>
import EditField from '@/components/EditField'
import LocationPicker from '@/components/LocationPicker'

export default {
  name: 'EventEditLocation',
  components: { EditField, LocationPicker },
  props: {
    location: {
      type: Object,
      required: true
    },
    isEditting: {
      type: String,
      default: undefined
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    previousLocations: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      newLocation: undefined,
      oldLocation: undefined
    }
  },
  watch: {
    isLoading: function (newValue, oldValue) {
      if (oldValue && !newValue) {
        this.newLocation = undefined
        this.oldLocation = undefined
      }
    }
  },
  created: function () {},
  methods: {
    saveLocation(location, isNew) {
      this.$emit('on-save', location, isNew)
    },
    setPreviousLocation(location) {
      this.oldLocation = location
    }
  }
}
</script>

<style lang="scss"></style>
