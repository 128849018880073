<template>
  <edit-field
    id="image"
    title="Afbeelding"
    :value="imageUrl"
    :disabled="isEditting && isEditting !== 'image'"
    :is-loading="isLoading"
    is-image
    hide-save-button
    @edit="(id) => $emit('edit', id)"
    @cancel="$emit('cancel')"
  >
    <div class="flex justify-between items-center">
      <div
        class="w-24 h-24 sm:w-32 sm:h-32 rounded-xl bg-cover bg-center relative"
        :style="{ backgroundImage: `url('${imageUrl}')` }"
      ></div>
      <div v-if="!isUpdated" class="flex items-center flex-column sm:flex-row">
        <button
          v-if="image && image.file"
          class="button button-danger button-sm w-full sm:w-auto sm:mr-2"
          @click="$emit('delete')"
        >
          Verwijder afbeelding
        </button>
        <input
          id="event-image"
          type="file"
          class="opacity-0 overflow-hidden absolute -z-1"
          accept="image/*"
          @change="(event) => $emit('change-file', event)"
        />
        <label
          for="event-image"
          class="button button-sm button-secondary button-icon my-2 hover:cursor-pointer w-full sm:w-auto"
        >
          <span class="flex-1">Nieuwe afbeelding</span>
        </label>
      </div>
      <button
        v-if="isUpdated"
        class="button button-sm button-primary button-icon my-4"
        @click="$emit('save')"
      >
        <icon-loader v-if="isLoading" />
        <span class="flex-1">Opslaan</span>
      </button>
    </div>
  </edit-field>
</template>

<script>
import EditField from '@/components/EditField'
import IconLoader from '@/components/icons/IconLoader'

export default {
  name: 'EventEditImage',
  components: {
    EditField,
    IconLoader
  },
  props: {
    image: {
      type: [String, Object, File],
      default: ''
    },
    imageUrl: {
      type: String,
      default: ''
    },
    isEditting: {
      type: String,
      default: undefined
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isUpdated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  }
}
</script>
