<template>
  <div class="pb-20">
    <template v-if="dateStatus === 'success'">
      <app-bar title="" :show-back-button="true" :back-button-path="backPath" />
      <div
        class="container container-event-view"
        :class="{ 'has-fixed-action-button': actionButtonContainerFixed }"
      >
        <h1 class="page-title event-title">
          {{ eventDate.name }}
        </h1>
        <event-item
          class="margin-top-30"
          :num-of-not-full-events="numberOfNotFullDates"
          :name="eventDate.name"
          :activity-icon="eventDate.activity.activityIcon"
          :event-image="eventImage"
          :cost="eventDate.costFeeIncluded"
          :start-time="eventDate.startDateTimeFormatted"
          :end-time="eventDate.endDateTimeFormatted"
          :end-date="eventDate.endDateTime"
          :date="eventDate.date"
          :location="eventDate.location.name"
          :maximum-participants="eventDate.maximumParticipants"
          :number-of-participants="participantsAttendingYes.length"
          :finished-number-of-participants="participantsAttendingYes.length"
          :location-full="`
            ${eventDate.location.name},
            ${eventDate.location.adress},
            ${eventDate.location.postalCode},
            ${eventDate.location.city}
          `"
          :location-lat-lng="
            eventDate.location.coordinates !== undefined
              ? eventDate.location.coordinates.lat +
                ',' +
                eventDate.location.coordinates.lng
              : null
          "
          :show-name="false"
          :event-is-finished="eventDateIsFinished"
          :event-is-cancelled="eventDate.cancelled"
        />
        <h2>Details</h2>
        <div>
          <nl2br tag="p" :text="eventDate.description" />
          <div v-if="eventDate.cancelled" class="alert alert-warning">
            Deze activiteit is geannuleerd, je kunt je dus niet meer aanmelden
          </div>
        </div>
        <hr class="my-3.5 border-gray-300" />

        <template v-if="!eventDateIsFinished && !eventDate.cancelled">
          <h1 class="page-title text-center">Uitnodigen</h1>
          <div class="wrapper-share-options">
            <a
              :href="`https://api.whatsapp.com/send?&text=${shareMessage}`"
              target="_blank"
              class="item"
            >
              <round-icon
                :size="75"
                :border-size="2"
                icon-class="fab fa-whatsapp"
              />
            </a>
            <a
              :href="
                'https://www.facebook.com/sharer/sharer.php?u=' +
                encodeURI(eventDate.url) +
                '&quote=' +
                shareMessage
              "
              target="_blank"
              class="item text-center"
            >
              <round-icon
                :size="75"
                :border-size="2"
                icon-class="fab fa-facebook-f"
              />
            </a>
            <a
              :href="
                'mailto:?subject=' +
                encodeURI('Inviplay uitnodiging: ' + eventDate.name) +
                '&body=' +
                shareMessage
              "
              target="_blank"
              class="item text-right"
            >
              <round-icon
                :size="75"
                :border-size="2"
                icon-class="fal fa-envelope"
              />
            </a>
            <div class="clearfix" />
          </div>

          <a
            v-if="moreDates.length"
            href="#"
            class="btn btn-white-border-green btn-block btn-relative margin-top-45"
            @click.prevent="updateParticipantDate({ showAllDates: true })"
          >
            bekijk alle data
            <i class="fal fa-calendar-alt btn-icn-right" />
          </a>
        </template>
        <template>
          <h1 class="page-title text-center margin-top-45">Deelnemers</h1>
          <div class="my-8">
            <div
              v-if="participantsAttendingYes.length"
              class="flex content-around my-8 flex-wrap"
            >
              <div
                v-for="(participant, index) in participantsAttendingYes"
                :key="index"
                class="flex flex-column w-1/3 sm:w-1/4 items-center mb-4"
                @click.prevent="routeToProfileView(participant.id)"
              >
                <initials-avatar
                  v-if="
                    participant.avatarUrl === null ||
                    participant.avatarUrl === undefined
                  "
                  :first-name="participant.firstName"
                  :last-name="participant.lastName"
                  :size="16"
                  class="mb-4"
                />

                <div
                  v-else
                  class="mb-4 w-16 h-16 rounded-full bg-cover bg-center inline-block"
                  :style="{
                    backgroundImage: `url('${participant.avatarUrl}')`
                  }"
                />

                <p class="text-center text-sm">
                  {{ `${participant.firstName} ${participant.lastName}` }}
                </p>
              </div>
            </div>
            <template v-else>
              <p class="text-no-result">Er zijn nog geen deelnemers</p>
            </template>
            <a
              href="#"
              class="btn btn-white-border-green btn-block btn-relative margin-top-10 to-participants"
              @click.prevent="routeToParticipants()"
            >
              toon alle deelnemers
            </a>
          </div>
          <hr class="my-3.5 border-gray-300" />
        </template>
        <template v-if="!eventDateIsFinished && !eventDate.cancelled">
          <h1 class="page-title text-center">Berichten (openbaar)</h1>
          <p v-if="!authId" class="text-center container">
            <small>Log in om een bericht te plaatsen</small>
          </p>
          <div class="wrapper-comments">
            <messages v-if="messages.length > 0" :items="messages" />
            <template v-else>
              <p class="text-no-result">Er zijn nog geen berichten geplaatst</p>
            </template>
            <form v-if="showNewMessageForm" @submit.prevent="sendMessage">
              <div class="form-group">
                <textarea
                  v-model="messageInput"
                  placeholder="Typ hier je bericht..."
                  class="form-control"
                />
              </div>
              <button
                type="submit"
                class="btn btn-white-border-green btn-block"
              >
                plaats bericht
              </button>
            </form>
            <a
              v-if="!showNewMessageForm && $store.getters.isAuthenticated"
              href="#"
              class="btn btn-white-border-green btn-block"
              @click.prevent="toggleNewMessageForm(true)"
            >
              schrijf bericht
            </a>
          </div>
        </template>
      </div>
      <div
        v-if="!eventDateIsFinished && !eventDate.cancelled"
        class="container action-button-container"
        :class="{ fixed: actionButtonContainerFixed }"
      >
        <a
          v-if="currentUserIsOwner"
          href="#"
          class="btn btn-white-border-green btn-block"
          @click.prevent="editEvent"
        >
          event aanpassen
        </a>

        <template v-else>
          <a
            v-if="currentUserIsParticipant"
            href="#"
            class="btn btn-green btn-block"
            @click.prevent="updateParticipantDate()"
          >
            aanwezigheid aanpassen
          </a>
          <a
            v-else-if="!eventDate.isFull"
            href="#"
            class="btn btn-green btn-block"
            @click.prevent="updateParticipantDate()"
          >
            doe je mee?
          </a>
          <a
            v-else-if="
              eventDate.isFull &&
              eventDate.allDates.length &&
              eventDate.allDates.some(
                (date) => !date.isFull && isAfterNow(date.endDateTime)
              )
            "
            href="#"
            class="btn btn-green btn-block"
            @click.prevent="updateParticipantDate({ showAllDates: true })"
          >
            doe mee op een andere dag
          </a>
        </template>
      </div>
    </template>
    <template v-else-if="dateStatus === 'error'">
      <app-bar
        :title="'Event niet gevonden'"
        :show-back-button="true"
        :back-button-path="backPath"
      />
      <p class="text-center">
        <em>Het evenement bestaat niet of niet meer!</em>
      </p>
    </template>
    <template v-else-if="event">
      <app-bar title="" :show-back-button="true" :back-button-path="backPath" />
      <div
        class="container container-event-view"
        :class="{ 'has-fixed-action-button': actionButtonContainerFixed }"
      >
        <h1 class="page-title event-title">
          {{ event.name }}
        </h1>
        <event-item
          class="margin-top-30"
          :is-proposal="true"
          :name="event.name"
          :activity-icon="event.activity_type.activityIcon"
          :event-image="event.imageUrl"
          :cost="event.costFeeIncluded"
          :location="event.location.name"
          :maximum-participants="event.maximumParticipants"
          :location-full="`
            ${event.location.name},
            ${event.location.adress},
            ${event.location.postalCode},
            ${event.location.city}
          `"
          :location-lat-lng="
            event.location.coordinates !== undefined
              ? event.location.coordinates.lat +
                ',' +
                event.location.coordinates.lng
              : null
          "
          :show-name="false"
          :event-is-cancelled="event.cancelled"
        />
        <h2>Details</h2>
        <div>
          <nl2br tag="p" :text="event.description" />
          <div v-if="event.cancelled" class="alert alert-warning">
            Deze activiteit is geannuleerd, je kunt je dus niet meer aanmelden
          </div>
        </div>
      </div>
    </template>
    <spinner-loader v-if="loading || paymentStatusLoading" />
    <tab-bar selected="search" />
    <modal
      v-if="!loading && !paymentStatusLoading"
      :show="showFeedbackModal"
      :show-close-icon="true"
      @close="closeFeedbackModal"
    >
      <template
        v-if="(action === 'payment' && paymentSuccess) || action !== 'payment'"
        v-slot:header
      >
        Gelukt!
      </template>
      <template
        v-else-if="action === 'payment' && paymentPending"
        v-slot:header
      >
        In behandeling
      </template>
      <template v-else v-slot:header>Oeps...</template>
      <template v-if="action === 'payment'">
        <p v-if="paymentSuccess">
          Je betaling is ontvangen en je bent aangemeld voor deze activiteit!
        </p>
        <p v-else-if="paymentPending">
          Je betaling is in behandeling. Zodra je betaling bij ons binnen is
          ontvang je van ons een bevestiging per e-mail en word je aangemeld
          voor deze activiteit.
        </p>
        <p v-else>
          Er is iets fout gegaan met de betaling. Neem contact met ons op via
          contact@inviplay.nl, dan proberen we het zo snel mogelijk voor je op
          te lossen.
        </p>
      </template>
      <template v-else-if="action === 'refund'">
        <p>
          Je aanwezigheid is bijgewerkt en je betaling wordt binnen vijf
          werkdagen teruggeboekt.
        </p>
      </template>
      <template v-else-if="action === 'signup'">
        <p>Je bent aangemeld voor deze activiteit!</p>
      </template>
      <template v-else>
        <p>Je hebt je aanwezigheid aangepast voor deze activiteit!</p>
      </template>
    </modal>
  </div>
</template>

<script>
import moment from 'moment'
import { DateTime } from 'luxon'
import Nl2br from 'vue-nl2br'
import { mapGetters, mapState } from 'vuex'
import noImage from '@/assets/images/img_no-image.png'
import AppBar from '@/components/AppBar'
import TabBar from '@/components/TabBar'
import RoundIcon from '@/components/RoundIcon'
import EventItem from '@/components/EventItem'
import SpinnerLoader from '@/components/SpinnerLoader'
import { EVENT_DATES_GET, EVENT_DATES_GET_ERROR } from '@/store/actions/date'
import { PAYMENT_GET_STATUS_BY_ID } from '@/store/actions/payment'
import { EVENT_VIEW } from '@/store/actions/event'
import api from '@/utils/api'
import InitialsAvatar from '@/components/InitialsAvatar'
import config from '@/utils/config'
import Modal from '../../components/Modal.vue'
import Messages from '../../components/Messages.vue'

export default {
  name: 'EventView',
  components: {
    AppBar,
    TabBar,
    RoundIcon,
    EventItem,
    SpinnerLoader,
    Nl2br,
    InitialsAvatar,
    Modal,
    Messages
  },
  data() {
    return {
      showNewMessageForm: false,
      showFeedbackModal: false,
      messageInput: null,
      currentUserIsParticipant: false,
      datesAvailable: true,
      dates: [],
      profile: null,
      participantsAttendingYes: [],
      routeName: this.$route.name,
      securityHash: this.$route.params.securityHash,
      dateId: this.$route.params.dateId,
      eventId: this.$route.params.eventId,
      origin: this.$route.query.origin,
      paymentId: this.$route.query.pid,
      paymentStatusLoading: false,
      numberOfRepeats: 0,
      messages: []
    }
  },
  computed: {
    ...mapGetters(['authId']),
    ...mapState({
      participantProfile: (state) => state.participant.ownProfile,
      eventDate: (state) => state.date.eventDate,
      participants: (state) => state.date.participants,
      dateStatus: (state) => state.date.status,
      loading: (state) =>
        state.date.status === 'loading' || state.payment.loading,
      paymentStatus: (state) => state.payment.status,
      event: (state) => state.event.eventView
    }),
    action: function () {
      return this.$route.query.action
    },
    backPath: function () {
      return this.origin ? `/${this.origin}` : '/'
    },
    eventImage: function () {
      return (this.eventDate && this.eventDate.imageUrl) || noImage
    },
    currentUserIsOwner: function () {
      return this.eventDate && this.eventDate.ownerId === parseInt(this.authId)
    },
    actionButtonContainerFixed: function () {
      return !this.currentUserIsOwner
    },
    shareMessage: function () {
      return this.eventDate
        ? `Doe je ook mee met ${this.eventDate.name}? Geef je op via inviplay: ${this.eventDate.url}/${this.securityHash}`
        : null
    },
    numberOfNotFullDates: function () {
      return this.eventDate.allDates && this.eventDate.allDates.length
        ? this.eventDate.allDates.filter(
            (date) =>
              !date.isFull && moment(date.startDateTime).isAfter(moment())
          ).length
        : 0
    },
    eventDateIsFinished: function () {
      return moment(this.eventDate.endDateTime).isBefore(moment())
    },
    moreDates: function () {
      return this.eventDate && this.eventDate.allDates
        ? this.eventDate.allDates.filter(
            (date) =>
              DateTime.fromISO(date.startDateTime) > DateTime.local() &&
              date.id !== this.eventDate.id
          )
        : []
    },
    paymentSuccess() {
      return this.paymentId && this.paymentStatus
        ? this.paymentStatus[this.paymentId] === 'paid' ||
            this.paymentStatus[this.paymentId] === 'completed'
        : false
    },
    paymentPending() {
      return this.paymentId && this.paymentStatus
        ? this.paymentStatus[this.paymentId] === 'pending'
        : false
    },
    groupId() {
      return this.eventDate && this.eventDate.groupId
    }
  },
  watch: {
    groupId(groupId) {
      if (groupId) {
        this.getMessages()
      }
    },
    action(action) {
      this.showFeedbackModal =
        action && config.featureFlags.creditcards ? true : false
    },
    participantProfile(participantProfile) {
      this.profile = participantProfile
    },
    participants(participants) {
      if (participants.length) {
        this.currentUserIsParticipant = participants.some(
          (participant) => participant.id === parseInt(this.authId)
        )
        this.participantsAttendingYes = participants.filter(
          (participant) => participant.attendance === 'yes'
        )
      }
    },
    paymentStatus(paymentStatus) {
      const status = paymentStatus[this.paymentId]
      if (status === 'open' || status === 'pending' || status === 'created') {
        this.retryPaymentStatus()
      } else if (
        status === 'expired' ||
        status === 'failed' ||
        status === 'canceled' ||
        status === 'cancelled'
      ) {
        this.paymentStatusLoading = false
        this.showFeedbackModal = true
      } else {
        this.paymentStatusLoading = false
        this.showFeedbackModal = true
      }
    }
  },
  created: async function () {
    if (this.action && config.featureFlags.creditcards) {
      this.showFeedbackModal = true
      if (this.action === 'payment' && this.paymentId) {
        // get payment status of this user
        this.paymentStatusLoading = true
        this.$store.dispatch(PAYMENT_GET_STATUS_BY_ID, {
          paymentId: this.paymentId
        })
      }
    }

    // this is needed for the legacy url `/event-view/id/dateId/securityHash`
    if (this.routeName === 'EventView' && this.eventId && this.securityHash) {
      if (!this.dateId) {
        try {
          const response = await api.get(
            `event/${this.eventId}?securityHash=${this.securityHash}`
          )
          if (response && response.data && response.data.nextDate) {
            this.dateId = response.data.nextDate.id
          } else {
            this.$store.commit(EVENT_DATES_GET_ERROR)
          }
        } catch (e) {
          this.$store.commit(EVENT_DATES_GET_ERROR, e)
        }
      }
      this.$store.dispatch(EVENT_DATES_GET, {
        dateId: this.dateId,
        securityHash: this.securityHash
      })
      this.$router.push(
        `/events/${this.dateId}/${this.securityHash}${
          this.origin ? `?origin=${this.origin}` : ''
        }`
      )
    }
    // event has no date yet
    else if (this.routeName === 'EventProposal' && this.eventId) {
      this.$store.dispatch(EVENT_VIEW, {
        eventID: this.eventId,
        securityHash: this.securityHash
      })
      // get the requested event date
    } else {
      this.$store.dispatch(EVENT_DATES_GET, {
        dateId: this.dateId,
        securityHash: this.securityHash
      })
    }
  },
  mounted: async function () {
    this.getMessages()
  },
  methods: {
    getMessages: async function () {
      if (!this.groupId) return
      try {
        const messageRequest = await api.get(
          `/group/${this.groupId}/message?filter[order]=sent_at%20DESC`
        )
        if (messageRequest && messageRequest.data) {
          this.messages = messageRequest.data
        }
      } catch (e) {
        console.log('error loading messages', e)
      }
    },
    sendMessage: async function () {
      try {
        const result = await api.post(`/group/${this.groupId}/message`, {
          content: this.messageInput
        })
        if (result && result.data) {
          this.getMessages()
        }
      } catch (e) {
        console.log('error sending message', e)
      }
      this.messageInput = ''
      this.showAddMessage = false
    },
    retryPaymentStatus: function () {
      let repeatGetStatus
      if (this.numberOfRepeats < 5) {
        repeatGetStatus = setTimeout(() => {
          this.$store.dispatch(PAYMENT_GET_STATUS_BY_ID, {
            paymentId: this.paymentId
          })
          this.numberOfRepeats++
        }, 1000)
      } else {
        clearTimeout(repeatGetStatus)
        this.paymentStatusLoading = false
        this.showFeedbackModal = true
      }
    },
    closeFeedbackModal: function () {
      this.$router.push(this.$route.path)
      // refetch event dates to update the attendance status
      this.$store.dispatch(EVENT_DATES_GET, {
        dateId: this.dateId,
        securityHash: this.securityHash
      })
    },
    toggleNewMessageForm: function (show) {
      this.showNewMessageForm = show
      if (show) {
        setTimeout(function () {
          window.scrollTo(
            0,
            document.body.scrollHeight || document.documentElement.scrollHeight
          )
        }, 100)
      }
    },
    isValid: function () {
      let isValid = true
      let message = null
      if (!this.messageInput) {
        isValid = false
        message = 'Bericht is verplicht'
      }
      if (message !== null) {
        alert(message)
      }
      return isValid
    },
    updateParticipantDate: function (options) {
      const showAllDates = options ? options.showAllDates : false
      this.routeToEventSignup(showAllDates)
    },
    editEvent: function () {
      this.routeToEventEditView()
    },
    isAfterNow: function (datetime) {
      return moment(datetime).isAfter(moment())
    },
    routeToProfileView: function (id) {
      this.$router.push('/profile-view/' + id)
    },
    routeToEventSignup: function (showAllDates) {
      this.$router.push(
        `/events/${this.dateId}/signup/${this.securityHash}?showAllDates=${showAllDates}`
      )
    },
    routeToEventEditView: function () {
      this.$router.push(
        `/event-edit/${this.eventDate.eventId}/${this.securityHash}`
      )
    },
    routeToParticipants: function () {
      this.$router.push(
        `/events/${this.dateId}/${this.securityHash}/participants`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

h1.event-title {
  margin-bottom: 0;
  margin-top: 1.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.component-event-item {
  margin-top: 10px;
}

.container-event-view {
  padding-bottom: 15px;
}
.container-event-view.has-fixed-action-button {
  padding-bottom: 65px;
}
.container-event-view .wrapper-description {
  text-align: center;
  font-size: 0.938rem;
  margin: 25px 0 35px 0;
}
.container-event-view .wrapper-share-options {
  margin: 25px 0 35px 0;
}
.container-event-view .wrapper-share-options .item {
  float: left;
  width: 33%;
}
.participant-list-with-fade {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(rgba(0, 0, 0, 0))
  );
}
.to-participants {
  margin-bottom: 30px;
}
@media (max-width: 320px) {
  .participant-list li {
    width: 85px;
  }
  .participant-list li .participant-name {
    padding: 0 5px;
  }
}
.action-button-container.fixed {
  height: 50px;
  position: fixed;
  bottom: 70px;
  left: 0;
  right: 0;
}
.text-nothing-found,
.text-no-result {
  font-size: 0.9375rem;
  text-align: center;
}
.wrapper-comments .comment-item .comment-details .participant-avatar {
  width: 55px;
  height: 55px;
  background-size: cover;
  background-position: center;
  @include border-radius(55px);
  float: left;
}
.wrapper-comments .comment-item .comment-details .participant-no-avatar {
  float: left;
}
.wrapper-comments .comment-item .comment-details .comment-data {
  float: left;
  margin-left: 15px;
  width: calc(100% - 75px);
  padding-top: 2px;
}
.wrapper-comments
  .comment-item
  .comment-details
  .comment-data
  .participant-name {
  font-weight: 600;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wrapper-comments .comment-item .comment-details .comment-data .comment-date {
  font-size: 0.9375rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wrapper-comments .comment-item .comment-message {
  font-size: 0.9375rem;
  margin: 5px 0 25px 70px;
}
</style>
